import React from 'react'
import Section from './Section'
import Button from './Button'
import Column from './Column'
import Row from './Row'
import {en, es} from '../languages/langs'

import banner from '../images/Banner.jpg'

const Hero = ({activeLang}) => {
	const lang = {en, es}[activeLang]
	return (
        <Section id="hero" >
            <Row extraClasses="content-width center creme-text">
                <Column extraClasses="m-top__xxx-large mobile-p-left__large mobile-p-right__xx-large mobile-pos--rel">
				<span className="hero-header line-height__small text-shadow" dangerouslySetInnerHTML={{__html: lang['Since 1985']}}>
					</span>
                </Column>
            </Row>
            <Row extraClasses="content-width center">
                <Column extraClasses="m-top__large m-bottom__xxx-large mobile-pos--rel mobile-p-left__large">
                    <Button link="/aboutus" extraClasses="p-top__medium p-bottom__medium">{lang['About us']}</Button>
                </Column>
                <img src = {banner} alt= "animalsS"/>
            </Row>
        </Section>
	)
}

export default Hero
import React from 'react'

import Row from '../Row'
import Column from '../Column'

import {en, es} from '../../languages/langs'

const Checkbox = props => {
	const lang = {en, es}[props.activeLang]

	return (
		<label htmlFor={props.id} className={props.extraClasses}>
			<Row>
				<Column extraClasses={`size__one-of-${props.boxColumnSize && props.boxColumnSize === 'small' ? '24' : 'twelve'} mobile-size__one-of-twelve`}>
					<input type="checkbox" id={props.id} name={props.id} onClick={props.onClick} />
				</Column>
				<Column extraClasses="size__eleven-of-twelve mobile-size__eleven-of-twelve">
					<span>{lang[props.labelKey]}</span>
				</Column>
			</Row>
		</label>
	)
}

export default Checkbox
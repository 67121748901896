import React from 'react'
import { Link } from 'react-router-dom'

const Button = props => {
	if (props.onClick && typeof props.onClick == 'function') {
		return (
			<button onClick={props.onClick} className={`button${props.extraClasses ? ' ' + props.extraClasses : ''}`}>{props.children}</button>
		)
	} else {
		return (
			<Link to={props.link} className={`button${props.extraClasses ? ' ' + props.extraClasses : ''}`}>{props.children}</Link>
		)
	}
}

export default Button
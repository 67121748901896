import React, { useEffect } from 'react'
import * as FaIcons from 'react-icons/fa'

import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import SummaryTile from '../components/SummaryTile'
import Button from '../components/Button'

import {en, es} from '../languages/langs'
import {projects} from '../data/projects'

import ImageCard from '../components/ImageCard'

const AboutUs = ({activeLang}) => {
	const lang = {en, es}[activeLang]

	useEffect(() => {
		window.location.hash = window.location.hash
	})

	const renderedProjectRows = projects.map(row => {
		const renderedProjects = row.cells.map(project => {
			return (
				<ImageCard
					image={project.image}
					key={project.image}
					titleKey={project.title}
					contentKey={`project_${project.title}`}
					type="horizontal"
					width="one-of-two"
					activeLang={activeLang}
				/>
			)
		})
		return (
			<React.Fragment key={row.rowId}>
				<Section extraClasses="content-width center mobile-p-left__large mobile-p-right__large">
					<Row>
						{renderedProjects}
					</Row>
				</Section>
				<hr className="creme-divider content-width center m-top__medium m-bottom__medium"></hr>
			</React.Fragment>
		)
	})

	return (
		<React.Fragment>
			<PageHeader titleKey="About us" image="images/papegaai.jpg" bgPos="60% 50%" activeLang={activeLang} />
			<Section extraClasses="content-width center mobile-p-left__large mobile-p-right__large">
				<Row>
					<Column extraClasses="size__ten-of-twelve">
						<SummaryTile
							titleKey="A worldwide network"
							subTitleKey="Contacts with over 15000 institutions"
							showButton={false}
							extraClasses="m-top__x-large"
							anchor="worldwide-network"
							activeLang={activeLang}
						>
							<div
								className="summary-tile__content line-height__large text-columns--2 m-top__medium"
								dangerouslySetInnerHTML={{__html: lang['about_us_intro']}}>

							</div>
						</SummaryTile>
					</Column>
					<Column extraClasses="size__two-of-twelve p-left__x-large pull-up--mediumsmall pull-up--mobile-none mobile-size__one-of-two mobile-p-left__none">
						<div className="framed-image framed-image--largeheight m-top__medium">
							<div className="framed-image__frame">
								<div className="framed-image__image" style={{backgroundImage: "url('images/foto-john-2021.jpg')"}}></div>
							</div>
						</div>
						<h4 className="browngrey-text m-top__medium m-bottom__medium">John Rens</h4>
						<span className="browngrey-text">{lang['Founder of International Zoo Services']}</span>
					</Column>
				</Row>
			</Section>
			<Section extraClasses="creme-background p-top__large p-bottom__xx-large m-top__x-large mobile-p-left__large mobile-p-right__large">
				<Row extraClasses="center content-width">
					<Column extraClasses="size__one-of-one">
						<SummaryTile
							extraClasses="m-top__large"
							titleKey="Our services"
							showButton={false}
							activeLang={activeLang}
						>
							<div className="summary-tile__content line-height__large text-columns--3 m-top__medium">
								<div className="keep-together">
								<h4 className="browngrey-text m-bottom__small" id="howwework">{lang['Location-service: how we work']}</h4>
								<p className="m-top__medium">{lang['howwework_text']}</p>
								</div>
								<div className="keep-together">
								<h4 className="browngrey-text m-bottom__small" id="transport">{lang['Transport and documentation-service: work for specialists']}</h4>
								<p className="m-top__medium">{lang['transport_text']}</p>
								</div>
								<div className="keep-together" id="breedingloan" style={{breakBefore: "column"}}>
									<h4 className="browngrey-text m-bottom__small">{lang['Breeding-loan and donations: especially for EAZA or AZA -members']}</h4>
									<p className="m-top__medium">{lang['breeding_text']}</p>
								</div>
								<h4 className="browngrey-text m-bottom__small" id="exchange">{lang['Exchange']}</h4>
								<p className="m-top__medium">{lang['exchange_text']}</p>
								<div className="keep-together" style={{breakBefore: "column"}}>
									<h4 className="browngrey-text m-bottom__small" id="specialprojects">{lang['Special projects: donations from government to government']}</h4>
									<p className="m-top__medium">{lang['special_projects_text']}</p>
								</div>
							</div>
						</SummaryTile>
					</Column>
				</Row>
			</Section>
			<Section extraClasses="content-width center">
				<Row>
					<Column extraClasses="size__eight-of-twelve">&nbsp;</Column>
					<Column extraClasses="column size__four-of-twelve float-right pull-up--x-large pull-down--large p-left__x-large pull-up--mobile-none mobile-pull-down--none mobile-p-right__x-large">
						<div
							className="framed-image m-top__medium framed-image--largeheight framed-image--has-content mobile-pull-down--small">
							<div className="framed-image__frame">
								<div className="framed-image__image" style={{backgroundImage: "url('images/vos.jpg')"}}></div>
								<h4 className="browngrey-text text--centered p-top__large">{lang['Follow our work and projects on:']}</h4>
								<div className="text--centered p-bottom__large">									
									<a href="https://www.facebook.com/internationalzooservices/" target="_blank" rel="noreferrer" >
										<FaIcons.FaFacebookSquare className='fa-icon'/>
									</a>									
									<a href="https://www.instagram.com/zooservices/" target="_blank" rel="noreferrer" >
										<FaIcons.FaInstagramSquare className='fa-icon'/>
									</a>
									<a href="https://www.youtube.com/channel/UCASeZJwxkle8FmL4p2QUv3g" target="_blank" rel="noreferrer" >
										<FaIcons.FaYoutubeSquare className='fa-icon'/>
									</a>
								</div>
							</div>
						</div>
					</Column>
				</Row>
			</Section>
			<Section extraClasses="content-width center m-top__large m-bottom__xx-large mobile-m-top__x-large mobile-p-left__large mobile-p-right__large">
				<Row>
					<Column extraClasses="size__eight-of-twelve mobile-m-top__large">
						<SummaryTile 
							titleKey="Our projects"
							subTitleKey="ourprojects_subtitle"
							showButton={false}
							activeLang={activeLang}
							anchor="ourprojects"
						/>
					</Column>
				</Row>
			</Section>
			<hr className="creme-divider content-width center m-top__medium m-bottom__medium"></hr>
			{renderedProjectRows}
			<Row>
				<h4 style={{fontSize: "1.5rem"}} className="browngrey-text text--centered p-top__large">{lang['Follow our work and projects on:']}</h4>
				<div className="text--centered ">									
					<a href="https://www.facebook.com/internationalzooservices/" target="_blank" rel="noreferrer" >
						<FaIcons.FaFacebookSquare className='fa-icon'/>
					</a>									
					<a href="https://www.instagram.com/zooservices/" target="_blank" rel="noreferrer" >
						<FaIcons.FaInstagramSquare className='fa-icon'/>
					</a>
					<a href="https://www.youtube.com/channel/UCASeZJwxkle8FmL4p2QUv3g" target="_blank" rel="noreferrer" >
						<FaIcons.FaYoutubeSquare className='fa-icon'/>
					</a>
				</div>					
			</Row>
			<PageHeader extraClasses="m-top__xx-large" titleKey="Our worldwide network" image="images/papegaai.jpg" bgPos="60% 50%" activeLang={activeLang}>
				<Button link="/contact-us" extraClasses="m-top__large">{lang['Contact us']}</Button>
			</PageHeader>
		</React.Fragment>
	)
}

export default AboutUs
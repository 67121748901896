import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import Home from '../pages/Home'
import AboutUs from '../pages/AboutUs'
import Register from '../pages/Register'
import Faq from '../pages/Faq'
import Login from '../pages/Login'
import CatalogPage from '../pages/CatalogPage'
import ProductPage from '../pages/ProductPage'
import InquiryPage from '../pages/InquiryPage'
import SearchPage from '../pages/SearchPage'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import InventoryListPage from '../pages/InventoryListPage'
import ContactPage from '../pages/ContactPage'
import UserSettingsPage from '../pages/UserSettingsPage'

import {speciesMenuSkeleton} from '../data/speciesMenuSkeleton'
import {BrowserRouter, Route} from 'react-router-dom'
import {api, sanctum} from '../services/api'

const App = () => {
	const [loggedIn, setLoggedInState] = useState(false)
	const [activeRoute, setActiveRoute] = useState('/')
	const [activeLang, setActiveLang] = useState('en')
	const [requestedSurplusIds, setRequestedSurplusIds] = useState(sessionStorage.getItem('requestedSurplusIds'))
	const [orderNames, setOrderNames] = useState({})
	const [activeUser, setActiveUser] = useState({name: ''})
	const [userId, setUserId] = useState(null)

	useEffect(() => {
		const checkSession = async () => {
			try {
				if(window.localStorage.getItem("accessToken")){
					const response = await api.get(`/user`)
					if (response.status === 200) {
						setActiveUser(response.data)
						setLoggedInState(true)
						setUserId(response.data.id)
					}
				}
				
			} catch (e) {
				if (e.response.status === 401) {
					setLoggedInState(false)
				}
			}
		}
		checkSession()
	}, [loggedIn])

	const logOut = async () => {
		await sanctum.get('/csrf-cookie')
		localStorage.removeItem('accessToken')
		const response = await api.post('/logout')
		if (response.status === 200) {
			setLoggedInState(false)
			setActiveRoute('/')
		}
	}

	const addToOrderNames = order => {
		orderNames[order.slug] = {"common_name": order.common_name, "common_name_spanish": order.common_name_spanish}
		setOrderNames(orderNames)
	}

	const updateActiveRoute = route => {
		setActiveRoute(route)
	}

	const updateRequestedSurplusIds = id => {
		let currentIds = sessionStorage.getItem('requestedSurplusIds')
		id = id.toString()
		currentIds = currentIds !== null ? currentIds.split(',') : [id]
		if (currentIds.indexOf(id) === -1) {currentIds.push(id)}
		sessionStorage.setItem('requestedSurplusIds', currentIds)
		setRequestedSurplusIds(currentIds.toString())
	}

	window.onpopstate = e => {
		setActiveRoute(window.location.pathname)
	}

	const renderedSpeciesRoutes = speciesMenuSkeleton.map(entry => {
		return (
			<Route key={entry.apiName} path={`/${entry.apiName}`}>
				{(window.location.pathname.split('/').length === 3 ||
				  window.location.pathname.split('/').length === 2) &&
				  window.location.pathname !== '/' &&
					<CatalogPage
						route={activeRoute}
						updateActiveRoute={updateActiveRoute}
						activeLang={activeLang}
						orderNames={orderNames}
					/>
				}
				{window.location.pathname.split('/').length === 4 &&
					<ProductPage
						route={activeRoute}
						updateActiveRoute={updateActiveRoute}
						activeLang={activeLang}
						updateRequestedSurplusIds={updateRequestedSurplusIds}
					/>
				}
			</Route>
		)
	})

	return (
		<BrowserRouter>
			<Header
				loggedIn={loggedIn}
				updateActiveRoute={updateActiveRoute}
				setActiveLang={setActiveLang}
				activeLang={activeLang}
				requestedSurplusIds={requestedSurplusIds}
				addToOrderNames={addToOrderNames}
				logOut={logOut}
			/>
			<div id="page-content">
				<Route path="/" exact>
					<Home
						activeLang={activeLang}
						loggedIn={loggedIn}
						updateActiveRoute={updateActiveRoute}
					/>
				</Route>
				<Route path="/aboutus" exact>
					<AboutUs activeLang={activeLang} />
				</Route>
				<Route path="/faq" exact>
					<Faq activeLang={activeLang} />
				</Route>
				<Route path="/register" exact>
					<Register activeLang={activeLang} />
				</Route>
				<Route path="/login" exact>
					<Login
						setLoggedInState={setLoggedInState}
						loggedIn={loggedIn}
						activeLang={activeLang}
						activeUser={activeUser}
					/>
				</Route>
				<Route path="/inquiry-list" exact>
					<InquiryPage
					    userId = {userId}
						activeLang={activeLang}
						setRequestedSurplusIds={setRequestedSurplusIds}
						requestedSurplusIds={requestedSurplusIds}
						loggedIn={loggedIn}
					/>
				</Route>
				<Route path="/search">
					<SearchPage
						activeLang={activeLang}
						activeRoute={activeRoute}
						updateActiveRoute={updateActiveRoute}
					/>
				</Route>
				{/* <Route path="/upload">
					<UploadPage
						activeLang={activeLang}
					/>
				</Route> */}
				<Route path="/contact-us">
					<ContactPage
						activeLang={activeLang}
						loggedIn={loggedIn}
					/>
				</Route>
				<Route path="/usersettings">
					<UserSettingsPage
						activeLang={activeLang}
						activeUser={activeUser}
						loggedIn={loggedIn}
					/>
				</Route>
				<Route path="/reset-password">
					<ResetPasswordPage
						activeLang={activeLang}
						activeUser={activeUser}
						loggedIn={loggedIn}
					/>
				</Route>
				<Route path="/inventory-list">
					<InventoryListPage
						activeLang={activeLang}
						loggedIn={loggedIn}
					/>
				</Route>
				{renderedSpeciesRoutes}
			</div>
			<Footer activeLang={activeLang} loggedIn={loggedIn} />
		</BrowserRouter>
	)
}

export default App

import React from 'react'

import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import Button from '../components/Button'

import {en, es} from '../languages/langs'
import {api} from '../services/api'

import {countryCodes} from '../data/countryCodes'

const ContactPage = ({activeLang, loggedIn}) => {
	const lang = {en, es}[activeLang]

	const renderedCountrySelect = []
	for (let code in countryCodes) {
		renderedCountrySelect.push(<option key={code} value={code}>{countryCodes[code]}</option>)
	}

	const sendContactForm = async () => {
		const name = document.getElementById('contactus-name').value
		const institution = document.getElementById('contactus-institution').value
		const email = document.getElementById('contactus-email').value
		const country = [...document.getElementById('contactus-country').getElementsByTagName('option')].find(opt => opt.selected).innerText
		const message = document.getElementById('message').value

		if (name.trim() === '' || institution.trim() === '' || email.trim() === '' || country.trim() === '' || message.trim() === '') {
			alert(lang['mand_info_missing2']);
		}
		else {
			const response = await api.post('/contact-us-no-member', {
				name,
				institution,
				email,
				country,
				message
			})
			if (response.status === 200) {
				alert(lang['Message received'])
				return
			}
			alert(lang['Something went wrong'])
		}
	}

	const sendMemberContactForm = async () => {
		const department = [...document.getElementById('departmentselect').getElementsByTagName('option')].find(opt => opt.selected).value
		const message = document.getElementById('message').value
		const response = await api.post('/contact-us', {
			department,
			message
		})
		if (response.status === 200) {
			alert(lang['Message received'])
			return
		}
		alert(lang['Something went wrong'])
	}

	return (
		<React.Fragment>
			<PageHeader titleKey="Contact us" image="images/contact_header1.jpg" bgPos="0% 50%" activeLang={activeLang} />
		{loggedIn &&
			<React.Fragment>		
				<Section extraClasses="content-width center m-top__large m-bottom__large">
					<Row>
						<Column extraClasses="size__one-of-one">
							<div className="center size__one-of-two">
								<h4>{lang['Please select which department you want to contact:']}</h4>
								<select name="" id="departmentselect" className="select--green mobile-size__one-of-one" style={{"width": "100%"}}>
									<option value="administrations-department">{lang['Administrations department: about invoices and payments']}</option>
									<option value="animal-database-manager">{lang['Animal database manager: to send your surplus and wanted lists']}</option>
									<option value="flamingo-services">{lang['Flamingo-services: about projects from Cuba, including flights schedules']}</option>
									<option value="inquiry-offer-manager">{lang['Inquiry-offer manager: about inquiries for prices, permits of projects']}</option>
									<option value="others">{lang['Others: any other subject']}</option>
									<option value="transport-manager">{lang['Transport-manager: about flight schedules, truck-transports']}</option>
								</select>
								<h5 className="m-top__large">{lang['Question body:']}</h5>
								<textarea id="message" className="inquiry-input" rows="4" style={{"width": "100%"}}></textarea>
								<Button onClick={() => {sendMemberContactForm()}} extraClasses="m-top__medium">{lang['Submit']}</Button>
							</div>
						</Column>
					</Row>
				</Section>
			</React.Fragment>
		}
		{!loggedIn &&
			<React.Fragment>
				{/*<Section extraClasses="content-width center text--centered m-top__large m-bottom__large">
					<h4>{lang['loginwarn_contact']}</h4>
					<Button link="/login">{lang['Login']}</Button>
				</Section>*/}
				<Section extraClasses="content-width center m-top__large m-bottom__large">
					<Row>
						<Column extraClasses="size__one-of-one">
							<div className="center size__one-of-two">
								<h5 className="m-top__large">* {lang['contactus_name']}</h5>
								<input type="text" name="contactus-name" id="contactus-name" className="input--green mobile-size__one-of-one" style={{"width": "100%"}} />
								<h5 className="m-top__large">* {lang['contactus_institution']}</h5>
								<input type="text" name="contactus-institution" id="contactus-institution" className="input--green mobile-size__one-of-one" style={{"width": "100%"}} />
								<h5 className="m-top__large">* {lang['contactus_email']}</h5>
								<input type="text" name="contactus-email" id="contactus-email" className="input--green mobile-size__one-of-one" style={{"width": "100%"}} />					
								<h5 className="m-top__large">* {lang['contactus_country']}</h5>
								<select name="contactus-country" id="contactus-country" className="select--green mobile-size__one-of-one" style={{"width": "50%"}}>
									<option key="" value="">--Select--</option>
									{renderedCountrySelect}
								</select>
								<h5 className="m-top__large">* {lang['Question body:']}</h5>
								<textarea id="message" className="inquiry-input" rows="4" style={{"width": "100%"}}></textarea>
								<Button onClick={() => {sendContactForm()}} extraClasses="m-top__medium">{lang['Submit']}</Button>
							    
							</div>
							<div className="center size__one-of-two text--centered">
							<img src="/images/zoo-services-logo.png" alt="" className="m-top__x-large " />			
							<h5 className='m-top__medium m-bottom__small'>Den Haag</h5>
							<h5 className='m-bottom__small'>The Netherlands</h5>
							<h5 className='m-bottom__small'>Phone/ whatsapp: +31854011610</h5>
							<h5>Email: <a href=' info@zoo-services.com' target="_blank"> info@zoo-services.com</a></h5>
							</div>
						</Column>
					</Row>
				</Section>
			</React.Fragment>
		}
		</React.Fragment>
	)
}

export default ContactPage
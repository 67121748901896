import React from 'react'
import PageHeader from '../components/PageHeader'
import SummaryTile from '../components/SummaryTile'
import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import Button from '../components/Button'

import {en, es} from '../languages/langs'
import {api} from '../services/api'

const InventoryListPage = ({activeLang, loggedIn}) => {
	const lang = {en, es}[activeLang]

	const getPDF = async () => {
		const response = await api.get(`/inventory-list?language=${activeLang === 'en' ? 'english' : 'spanish'}`)
		if (response.status === 200) {
			window.open(response.data.url, '_blank');
		}
	}

	return (
		<React.Fragment>
			<PageHeader titleKey="Download inventorylist" image="images/papegaai.jpg" bgPos="60% 50%" activeLang={activeLang} />
			<Section extraClasses="content-width center mobile-p-left__large mobile-p-right__large">
				<Row>
					<Column extraClasses="size__one-of-one">
						<SummaryTile
							titleKey="Thank you for your interest"
							subTitleKey="We appreciate it"
							showButton={false}
							extraClasses="m-top__x-large"
							activeLang={activeLang}
						>
							{loggedIn &&
							<React.Fragment>
								<div
									className="summary-tile__content line-height__large text-columns--2 m-top__medium"
									dangerouslySetInnerHTML={{__html: lang['inventorylist-loggedin']}}>
								</div>
								<button onClick={() => {getPDF()}} rel="noreferrer" className="button m-top__large">{lang['Download PDF inventorylist']}</button>
							</React.Fragment>
							}
							{!loggedIn &&
							<React.Fragment>
								<div
									className="summary-tile__content line-height__large text-columns--2 m-top__medium"
									dangerouslySetInnerHTML={{__html: lang['inventorylist-notloggedin']}}>

								</div>
								<Button link="/register" extraClasses="m-top__large">{lang['Register']}</Button>
							</React.Fragment>
							}
						</SummaryTile>
					</Column>
				</Row>
			</Section>
		</React.Fragment>
	)
}

export default InventoryListPage
import React, {useState, useEffect} from 'react'

import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import CatalogItem from '../components/CatalogItem'
import Loader from '../components/Loader'

import {en, es} from '../languages/langs'
import {api} from '../services/api'

const CatalogPage = ({activeLang, updateActiveRoute, activeRoute}) => {
	const lang = {en, es}[activeLang]

	const [specimensOnPage, setSpecimensOnPage] = useState([<Loader key="loader" extraClasses="m-bottom__xx-huge"/>])

	useEffect(() => {
		const getSpecimensForOrder = async () => {
			setSpecimensOnPage([<Loader key="loader" extraClasses="m-bottom__xx-huge"/>])
			const searchTerm = new URLSearchParams(window.location.search).get('q')

			const response = await api.get(`/available-animals-by-search?q=${searchTerm}`)
			const renderedSpecimenRows = response.data.map(specimen => {
				return (
					<Column key={specimen.id} extraClasses="size__one-of-three m-bottom__xx-large">
						<CatalogItem
							specimen={specimen}
							updateActiveRoute={updateActiveRoute}
							activeLang={activeLang}
						/>
					</Column>
				)
			})
			const renderedSpecimens = () => {
				return (
					<Row extraClasses="m-top__large">
						{renderedSpecimenRows.length > 0 &&
							renderedSpecimenRows
						}
						{renderedSpecimenRows.length === 0 &&
							<Row extraClasses="m-top__large m-bottom__large">
								<Column extraClasses="size__one-of-one text--centered">
									<h3 className="green-text">{lang["We're sorry, there are no results"]}</h3>
								</Column>
							</Row>
						}
					</Row>
				)
			}
			setSpecimensOnPage(renderedSpecimens)
		}
		getSpecimensForOrder()
	},[lang, activeRoute, updateActiveRoute, activeLang])

	return (
		<React.Fragment>
			<Section extraClasses="content-width center p-top__x-large p-bottom__x-large mobile-p-left__medium mobile-p-right__medium">
				<Row>
					<Column extraClasses="size__one-of-one">
					<b>M</b>= {lang['Male']}, <b>F</b>= {lang['Female']}, <b>U</b>= {lang['Sex is unknown']}, <b>Pr</b>= {lang['Pair']}, <b>x</b>= {lang['several specimens available']}, 
						<b>c.b.</b>= {lang['captive bred']}, <b>w.c.</b>= {lang['wild caught']}
					</Column>
				</Row>
			</Section>
			<Section extraClasses="content-width center">
				{specimensOnPage}
			</Section>
		</React.Fragment>
	)
}

export default CatalogPage
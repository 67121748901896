import React from 'react'

import Section from './Section'
import Row from './Row'

const Loader = (props) => {
	return (
		<Section extraClasses={`content-width center m-top__xx-large m-bottom__xx-large${props.extraClasses !== '' ? ' ' + props.extraClasses : ''}`}>
			<Row extraClasses="text--centered">
				<div className="lds-roller" style={{"display": "inline-block"}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
			</Row>
		</Section>
	)
}

export default Loader
export const countryCodes = {
	"1": "Afganistan",
	"2": "Albania",
	"3": "Algeria",
	"4": "Andorra",
	"5": "Angola",
	"6": "Anguilla",
	"7": "Antigua and Barbuda",
	"8": "Argentina",
	"9": "Armenia",
	"10": "Australia",
	"11": "Austria",
	"12": "Azerbaijan",
	"13": "Bahamas",
	"14": "Bahrain",
	"15": "Bangladesh",
	"16": "Barbados",
	"17": "Belarus",
	"18": "Belgium",
	"19": "Belize",
	"20": "Benin",
	"21": "Bermuda",
	"22": "Bhutan",
	"23": "Bolivia",
	"24": "Bosnie",
	"25": "Botswana",
	"26": "Brasil",
	"27": "Brunei",
	"28": "Bulgaria",
	"29": "Burkina Faso",
	"30": "Burundi",
	"31": "Caiman Islands",
	"32": "Cambodia",
	"33": "Cameroon",
	"34": "Canada",
	"35": "Cape Verde",
	"36": "Central Africa",
	"37": "Chad",
	"38": "Chile",
	"39": "China",
	"40": "Colombia",
	"41": "Comoros",
	"42": "Congo",
	"43": "Costa Rica",
	"44": "Cozumel",
	"45": "Croatia",
	"46": "Cuba",
	"47": "Curazao",
	"48": "Cyprus",
	"49": "Czech Republic",
	"50": "Denmark",
	"51": "Djibouti",
	"52": "Dominica",
	"53": "Dominican Republic",
	"54": "Ecuador",
	"55": "Egypt",
	"56": "El Salvador",
	"57": "Equatorial Guinea",
	"58": "Eritrea",
	"59": "Estonia",
	"60": "Ethiopia",
	"61": "Fiji",
	"62": "Finland",
	"63": "France",
	"64": "French Guyana",
	"65": "French Polynesia",
	"66": "Gabon",
	"67": "Gambia",
	"68": "Georgia",
	"69": "Germany",
	"70": "Ghana",
	"71": "Greece",
	"72": "Grenada",
	"73": "Guadeloupe",
	"74": "Guatemala",
	"75": "Guinea",
	"76": "Guinee-Bissau",
	"77": "Guyana",
	"78": "Haiti",
	"79": "Hawaii",
	"80": "Honduras",
	"81": "Hongkong",
	"82": "Hungary",
	"83": "Iceland",
	"84": "India",
	"85": "Indonesia",
	"86": "Iran",
	"87": "Iraq",
	"88": "Ireland",
	"89": "Israel",
	"90": "Italy",
	"91": "Ivory Coast",
	"92": "Jamaica",
	"93": "Japan",
	"94": "Jordan",
	"95": "Kazakhstan",
	"96": "Kenya",
	"97": "Kiribati",
	"98": "Kosovo",
	"99": "Kuwait",
	"100": "Kyrgyzstan",
	"101": "Laos",
	"102": "Latvia",
	"103": "Lebanon",
	"104": "Lesotho",
	"105": "Liberia",
	"106": "Libya",
	"107": "Liechtenstein",
	"108": "Lithuania",
	"109": "Luxembourg",
	"110": "Macedonie",
	"111": "Madagascar",
	"112": "Malawi",
	"113": "Malaysia",
	"114": "Maldives",
	"115": "Mali",
	"116": "Malta",
	"117": "Marshall Islands",
	"118": "Martinique",
	"119": "Mauritania",
	"120": "Mauritius",
	"121": "Mexico",
	"122": "Micronesia",
	"123": "Moldova",
	"124": "Monaco",
	"125": "Mongolia",
	"126": "Montenegro",
	"127": "Morocco",
	"128": "Mozambique",
	"129": "Myanmar",
	"130": "Namibia",
	"131": "Nauru",
	"132": "Nepal",
	"133": "Neth. Antilles",
	"134": "New Caledonia",
	"135": "New Zealand",
	"136": "Nicaragua",
	"137": "Niger",
	"138": "Nigeria",
	"139": "North-Korea",
	"140": "Norway",
	"141": "Oman",
	"142": "Pakistan",
	"143": "Palau",
	"144": "Palestine",
	"145": "Panama",
	"146": "Papua New Guinee",
	"147": "Paraguay",
	"148": "Peru",
	"149": "Philippines",
	"150": "Poland",
	"151": "Portugal",
	"152": "Puerto Rico",
	"153": "Qatar",
	"154": "Reunion",
	"155": "Romania",
	"156": "Russia",
	"157": "Rwanda",
	"158": "Saint Barthelemy",
	"159": "Saint Kitts and Nevis",
	"160": "Saint lucia",
	"161": "Saint Vincent & The Grenadines",
	"162": "Samoa",
	"163": "Sao Tome and Principe",
	"164": "Saudi Arabia",
	"165": "Senegal",
	"166": "Servia",
	"167": "Seychelles",
	"168": "Sierra Leone",
	"169": "Singapore",
	"170": "Slovakia",
	"171": "Slovenie",
	"172": "Solomon Islands",
	"173": "Somalia",
	"174": "Somaliland",
	"175": "South-Africa",
	"176": "South-Korea",
	"177": "Spain",
	"178": "Sri Lanka",
	"179": "Sudan",
	"180": "Suriname",
	"181": "Swaziland",
	"182": "Sweden",
	"183": "Switzerland",
	"184": "Syria",
	"185": "Taiwan",
	"186": "Tajikistan",
	"187": "Tanzania",
	"188": "Thailand",
	"189": "The Netherlands",
	"190": "Togo",
	"191": "Tonga",
	"192": "Trinidad and Tobago",
	"193": "Tunisia",
	"194": "Turkey",
	"195": "Turkmenistan",
	"196": "Turks and Caicos",
	"197": "Tuvalu",
	"198": "U.A.E.",
	"199": "U.S.A.",
	"200": "Uganda",
	"201": "Ukraine",
	"202": "United Kingdom",
	"203": "Uruguay",
	"204": "Uzbekistan",
	"205": "Vanuatu",
	"206": "Venezuela",
	"207": "Vietnam",
	"208": "Virgin Islands",
	"209": "Yemen",
	"210": "Zaire",
	"211": "Zambia",
	"212": "Zimbabwe"
}
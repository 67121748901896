import React from 'react'

import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import {faqs, esFaqs} from '../data/faqs'
import {en, es} from '../languages/langs'

const Faq = ({activeLang}) => {
	const curLangFaqs = activeLang === 'en' ? faqs : esFaqs
	function toggleFaq(e) {
		e.target.classList.toggle('button--faq-open')
		let content = e.target.parentElement.parentElement.getElementsByClassName('summary-tile__content')[0],
			span = content.getElementsByTagName('SPAN')[0],
			spanHeight = span.getBoundingClientRect().height,
			contentMarginTop = parseFloat(getComputedStyle(content).marginTop),
			isOpen = e.target.classList.contains('button--faq-open');
		content.style.height = isOpen ? (spanHeight + contentMarginTop) + 'px' : '0px';
	}

	const renderedFaqs = curLangFaqs.map(faqGroup => {
		return (
			<Section key={faqGroup.title} extraClasses="content-width center">
				<Row extraClasses="m-top__x-large m-bottom__large">
					<Column extraClasses="size__one-of-one creme-background p-top__large p-left__medium p-bottom__large">
						<h4 className="green-text m-bottom__none">{faqGroup.title}</h4>
					</Column>
				</Row>
				<Section extraClasses="content-width center mobile-p-left__large mobile-p-right__large">
				{faqGroup.faqs.map(faq => {
					return (
						<Row key={faq.title} extraClasses="faq-item">
							<Column extraClasses="size__eleven-of-twelve mobile-size__ten-of-twelve">
								<div className="summary-tile">
									<h3 className="green-text summary-tile__title m-bottom__none">{faq.title}</h3>
									<span className="sub-title sub-title--follows-h3 browngrey-text">
										{faq.q}
									</span>
									<div className="summary-tile__content line-height__large m-top__medium">
										<span dangerouslySetInnerHTML={{__html: faq.a}}>
										</span>
									</div>
								</div>
							</Column>
							<Column extraClasses="faq-button-container size__one-of-twelve mobile-size__two-of-twelve mobile-p-left__medium float-right">
								<button onClick={(e) => toggleFaq(e)} className="button button--faq float-right"></button>
							</Column>
							<hr className="creme-divider content-width center m-top__medium m-bottom__medium float-left"></hr>
						</Row>
					)
				})}
				</Section>
			</Section>
		)
	})

	return (
		<React.Fragment>
			<PageHeader titleKey="Faq" image="images/faq-vos.jpg" bgPos="0% 50%" activeLang={activeLang} />
			{renderedFaqs}
			<PageHeader showTitle={false} image="images/faq-vos.jpg" bgPos="0% 50%">
				<h2 className="creme-text m-bottom__none text-shadow">Faq</h2>
				<a href="https://cites.org/eng/disc/how.php" className="button m-top__large m-right__medium">All about cites permits (EN)</a>
				<a href="https://cites.org/esp/disc/how.php" className="button m-top__large m-right__medium">All about cites permits (ES)</a>
				<a href="http://ec.europa.eu/environment/cites/pdf/differences_b_eu_and_cites.pdf" className="button m-top__large m-right__medium">About Cites in European Union</a>
				<a href="http://www.oie.int/en/about-us/our-members/delegates-new/" className="button m-top__large m-right__medium">List of veterinary departments in the world actual</a>
				<a href="https://www.cites.org/eng/app/appendices.php" className="button m-top__large m-right__medium">CITES-Appendices</a>
			</PageHeader>
		</React.Fragment>
	)
}

export default Faq
import React from 'react'

import {en, es} from '../languages/langs'

const ImageCard = props => {
	const lang = {en, es}[props.activeLang]

	let width = props.width === undefined ? 'one-of-four' : props.width,
		className = `column image-card size__${width} p-right__medium p-left__medium`

	if (props.type === 'horizontal') {
		return (
			<div className={
				className +
				`${props.extraClasses === undefined ? '' : ` ${props.extraClasses}`}`
				}
			>
			<div className="row">
				<div 
					className={`
						image-card__image-container
						p-around__mediumsmall
						column size__four-of-twelve
						column`
					}
				>
					<img src={props.image} alt="" className="image-card__image" />
				</div>
				<div className="column size__eight-of-twelve p-left__medium">
					<h4 className="image-card__title browngrey-text m-top__medium m-bottom__small">
						{props.title === undefined ? 
							lang[props.titleKey] :
							props.title
						}
					</h4>
					<span className="image-card__text line-height__large">
						{props.children === undefined ?
							lang[props.contentKey] :
							props.children
						}
					</span>
				</div>
			</div>
		</div>
		)
	} else {
		return (
			<div className={className}>
				<div className="image-card__image-container p-around__mediumsmall">
					<img src={props.image} alt="" className="image-card__image" />
				</div>
				<h4 className="image-card__title browngrey-text m-top__medium">{lang[props.titleKey]}</h4>
				<span className="image-card__text line-height__large">
					{lang[props.contentKey]}
				</span>
			</div>
		)
	}
}

export default ImageCard
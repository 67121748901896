import React from 'react'

const Modal = ({ extraId, children}) => {
	return (
		<div id={`modal${extraId}`} className="modal" style={{display: "none"}}>
			<div className="modal__backdrop">
				<div className="modal__container">
					<div className="modal__content">
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Modal
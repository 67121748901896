import React from 'react'

import { en, es } from '../languages/langs'

const PageHeader = props => {
	const lang = {en, es}[props.activeLang]

	let pageTitle= ''
	if (props.showTitle !== false) {
		pageTitle = <h1 className="creme-text m-bottom__none">{lang[props.titleKey]}</h1>
	}

	return (
		<section className={`page-image-header${props.extraClasses ? ' ' + props.extraClasses : ''}`} style={{backgroundImage: `url('${props.image}')`, backgroundPosition: `${props.bgPos}`}}>
			<div className="row mobile-p-left__medium mobile-p-right__medium text-shadow">
				<div className={`
					page-image-header__title
					content-width
					center
					${props.lowVersion ? '' : 'p-top__xx-large p-bottom__xx-large'}
					${props.highVersion ? 'p-top__xxx-large p-bottom__xxx-large' : ''}
				`}>
					{pageTitle}
					{props.children || ''}
				</div>
			</div>
		</section>
	)
}

export default PageHeader
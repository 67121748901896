import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import Button from './Button'
import { Link, useHistory } from 'react-router-dom'

import {en, es} from '../languages/langs'
import {speciesMenuSkeleton} from '../data/speciesMenuSkeleton'
import {api} from '../services/api'

const Header = ({activeLang, loggedIn, updateActiveRoute, setActiveLang, requestedSurplusIds, addToOrderNames, logOut}) => {
	const lang = {en, es}[activeLang]
	const menuTitleKey = activeLang === 'en' ? 'menuTitle' : 'menuTitleEs'
	const history = useHistory()

	const setMobileSiteMenu = (state) => {
		if (!state) {
			document.getElementById('header__site-menu-container').classList.remove('active');
		} else {
			document.getElementById('header__site-menu-container').classList.add('active');
		}
	}

	const toggleSpeciesMenu = e => {
		document.getElementById('header__species-menu').classList.toggle('active')
		if (e) {
			document.getElementsByClassName('species-menu__mobile-toggle')[0].classList.toggle('active')
		}
	}

	const toggleMobileSearch = (e, state) => {
		if (state) {
			document.getElementById('main-search-container').classList.add('active');
			document.getElementById('main-search').focus();
			document.getElementById('species-menu-container').classList.add('faded');
		} else {
			document.getElementById('main-search-container').classList.remove('active')
			document.getElementById('species-menu-container').classList.remove('faded');
		}
	}
	function modal(state) {
		let m = document.getElementById('modal1')
		switch(state){
			case 'close':
				m.style.display = 'none';
				break
			case 'open': 
			    m.style.display = 'block';
				break
			default:
			    break
		}
    }  
	useEffect(() => {
		window.addEventListener('click', e => {
			if (window.screen.width < 1166 && e.target.classList.contains('icon') && e.target.parentElement.classList.contains('species-group__title')) {
				[...document.getElementsByClassName('species-submenu')].forEach(menu => {menu.classList.remove('species-submenu--mobile-active')})
				const subMenu = e.target.parentElement.getElementsByClassName('species-submenu')[0]
				subMenu.classList.toggle('species-submenu--mobile-active')
			}
		})
	}, [])

	const [speciesMenu, setSpeciesMenu] = useState(null)

	const toggleMobileSubmenu = (e) => {
		[...document.getElementsByClassName('species-submenu')].forEach(menu => {menu.classList.remove('species-submenu--mobile-active')})
		const source = e.target.tagName === 'I' ? e.target.parentElement : e.target
		const subMenu = source.getElementsByClassName('species-submenu')[0]
		if (subMenu) {
			subMenu.classList.toggle('species-submenu--mobile-active')
		}
	}

	const doSearch = (e, isKeyEvent) => {
		const mainSearch = document.getElementById('main-search')
		const isCorrectKeyCode = isKeyEvent ? e.keyCode === 13 : true
		if (isCorrectKeyCode && mainSearch.value !== '') {
			history.push(`/search?q=${mainSearch.value}`)
			updateActiveRoute(`/search?q=${mainSearch.value}`)
			mainSearch.value = ''
		}
	}

	useEffect(() => {
		const renderMainMenu = async () => {
			const menu = await Promise.all(speciesMenuSkeleton.map(async mainEntry => {
				const response = await api.get(`/get-class-menu?class=${mainEntry.apiName}`)
				const entries = response.data
				const renderedEntries = entries.map(entry => {
					addToOrderNames({
						"common_name": entry.common_name,
						"common_name_spanish": entry.common_name_spanish,
						"slug": entry.common_name_slug
					})
					return (
						<li key={entry.common_name_slug} className="species-submenu-item">
							<Link
								to={`/${mainEntry.apiName}/${entry.common_name_slug}`}
								onClick={() => {
									updateActiveRoute(`/${mainEntry.apiName}/${entry.common_name_slug}`);
									toggleSpeciesMenu();
								}}
							>
								{activeLang === 'en' ? entry.common_name: entry.common_name_spanish} ({entry.scientific_name})
							</Link>
						</li>
					)
				})
				return (
					<li
						key={mainEntry.apiName}
						className="species-group p-bottom__mediumsmall m-right__large mobile-p-top__small"
					>
						<span className="species-group__title creme-text">
							<Link
								className="creme-text species-group__title-link"
								to={`/${mainEntry.apiName}`}
								onClick={() => {
									updateActiveRoute(`/${mainEntry.apiName}`);
									toggleSpeciesMenu();
								}}
							>
								<i className={`icon ${mainEntry['icon']}`}></i>
								{mainEntry[menuTitleKey]}
							</Link>
							<i className="species-group__mobile-submenu-switch" onClick={(e) => {toggleMobileSubmenu(e)}}></i>
							<ul className={`species-submenu species-submenu--level1${renderedEntries.length > 15 ? ' species-submenu--2-column' : ''}`}>
								{renderedEntries}
							</ul>
						</span>
					</li>
				)
			}))
			setSpeciesMenu(menu)
		}
		renderMainMenu()
	}, [menuTitleKey, updateActiveRoute, activeLang, addToOrderNames])

	return (
		<header>
			<div className="header__upper p-bottom__medium row">
				<div className="center content-width">
					<div className="logo-holder header__block">
						<Link to="/">
							<img src="/images/zoo-services-logo.png" alt="" id="header-logo" className="main-logo" />
						</Link>
					</div>
					<div className="header__block header__site-menu-container" id="header__site-menu-container">
						<div className="row">
							<div className="column main-logo__inbetween-wrapper">
								<Link to="/">
									<img src="/images/zoo-services-logo.png" alt="" className="main-logo__inbetween" />
								</Link>
							</div>
							<div className="column">
								<div
									id="close-mobile-site-menu"
									onClick={() => {setMobileSiteMenu(false)}}
								></div>
								<ul className="site-menu">
									<li className="site-menu-item">
										<Link to="/" onClick={() => {setMobileSiteMenu(false)}}>{lang['Home']}</Link>
									</li>
									<li className="site-menu-item">
										<Link to="/aboutus" onClick={() => {setMobileSiteMenu(false)}}>{lang['About us']}</Link>
									</li>
									<li className="site-menu-item">
										<Link to="/faq" onClick={() => {setMobileSiteMenu(false)}}>{lang['FAQ']}</Link>
									</li>
									<li className="site-menu-item">
										<Link to="/contact-us" onClick={() => {setMobileSiteMenu(false)}}>{lang['Contact us']}</Link>
									</li>
									<li className="site-menu-item">
									        {loggedIn ?
										        <Link to="/inventory-list" onClick={() => {setMobileSiteMenu(false)}}>{lang['Surplus/Wanted lists']}</Link> :
										        <p style={{margin: '0', display: "inline-block",cursor: "pointer"}} onClick={()=> modal('open')} >{lang['Surplus/Wanted lists']}</p>
                                                                                }
									</li>
									{loggedIn &&
										<React.Fragment>
											{/* <li className="site-menu-item">
												<Link to="/upload" onClick={() => {setMobileSiteMenu(false)}}>{lang['Upload']}</Link>
											</li> */}
										</React.Fragment>
									}
								</ul>
								<Modal extraId={'1'}>
					                <h3>{lang['Please']}</h3>
					                <h4>{lang['Note that']}</h4>
					                <h4> {lang['If you are not registered, register'] }<Link to='/register' onClick={() => modal('close')} > {lang["here"]}</Link>.</h4>
					                <h4>{lang["If you are registered please"]} <Link to='/login' onClick={() => modal('close')} >{lang["log in"]}</Link>.</h4>
					                <Button onClick={() => modal('close')} extraClasses="m-top__medium m-right__small center">{lang['Close']}</Button>					
				                </Modal>
							</div>
						</div>
					</div>
					<div className="header__block header__site-meta-container float-right mobile-m-right__medium">
						<div className="row">
							<div className="column mobile-float__right mobile-column--autowidth">
								<div className="lang-choices column mobile-column--autowidth">
									<div className="lang-choice" onClick={() => setActiveLang('en')}>
										<img src="/images/gb.png" alt="United Kingdom flag" className="country-flag" />
									</div>
									<div className="lang-choice" onClick={() => setActiveLang('es')}>
										<img src="/images/es.png" alt="Spanish flag" className="country-flag" />
									</div>
								</div>
								<div className="inquiry-counter column mobile-column--autowidth">
									<Link to="/inquiry-list" style={{textDecoration: "none"}}>
										<span className="green-text">{lang['Inquiries to apply']} ({requestedSurplusIds !== null ? requestedSurplusIds.split(',').length : 0})</span>
									</Link>
								</div>
								<div
									className="column mobile-column--autowidth"
									id="toggle-mobile-site-menu"
									onClick={() => {setMobileSiteMenu(true)}}
								>
								</div>
							</div>
							<div className="user__controls column m-left__medium mobile-m-left__none mobile-float__right mobile-column--autowidth">
								{!loggedIn &&
									<Button link="/login" extraClasses="user__control button--inverse m-right__small">{lang['Login']}</Button>
								}
								{loggedIn &&
									<React.Fragment>
										<Button link="/usersettings" extraClasses="user__control button--inverse m-right__small">{lang['User settings']}</Button>
										<button onClick={() => {logOut()}} className="button user__control button--inverse">{lang['Logout']}</button>
									</React.Fragment>
								}
								{!loggedIn &&
									<Button link="/register" extraClasses="user__control">{lang['Register']}</Button>
								}
							</div>	
						</div>
					</div>
				</div>
			</div>
			{loggedIn &&
				<div className="header__lower green-background p-top__small mobile-p-bottom__medium row">
					<div className="center content-width">
						<div
							className="header__block header__species-menu-container mobile-p-left__medium"
							id="species-menu-container"
						>
							<div
								className="species-menu__mobile-toggle m-top__large m-right__large"
								onClick={(e) => toggleSpeciesMenu(e)}
							>
								<span className="species-group__title creme-text">
									{lang['Filter species']}
								</span>
							</div>
							
								<ul className="species-menu" id="header__species-menu">
									{speciesMenu}
								</ul>
						</div>
						<div 
							className="header__block header__search-container float-right m-top__mediumsmall mobile-m-right__medium"
							id="main-search-container"
							onClick={(e) => toggleMobileSearch(e, true)}
						>
							<input
								type="text"
								name="main-search"
								id="main-search"
								className="icon-right--search green-text"
								placeholder={lang['Search species in English or scientific name']}
								onBlur={(e) => toggleMobileSearch(e, false)}
								onKeyUp={(e) => {doSearch(e, true)}}
							/>
							<div
								id="main-search-triggerbutton"
								onClick={(e) => {doSearch(e, false)}}></div>
						</div>
					</div>
				</div>
            }
		</header>
	)
}

export default Header

import React from 'react'

import {Link} from 'react-router-dom'
import {en, es} from '../languages/langs'

const CheckArrowList = props => {
	const lang = {en, es}[props.activeLang]
	const entries = props.entries.map(entry => {
		return (
			<li className="creme-text" key={entry.link}>
				<Link to={entry.link} className="checked-link creme-text">{lang[entry.label]}</Link>
			</li>
		)
	})
	return (
		<ul className="check-arrow-list">
			{entries}
		</ul>
	)
}

export default CheckArrowList
import React from 'react'

const Column = props => {
	return (
		<div className={`column ${props.extraClasses}`} id={props.id !== undefined ? `${props.id}` : ''}>
			{props.children}
		</div>
	)
}

export default Column
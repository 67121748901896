import React from 'react'
import {Link} from 'react-router-dom'
import Modal from './Modal'
import Button from './Button'
import {en, es} from '../languages/langs'

const Footer = ({activeLang, loggedIn}) => {
	const lang = {en, es}[activeLang]
    function modal(state) {
		let m = document.getElementById('modal1')
		switch(state){
			case 'close':
				m.style.display = 'none';
				break
			case 'open': 
			    m.style.display = 'block';
				break
			default:
			    break
		}
    }  

	return (
		<footer>
			<div className="row green-background p-top__large p-bottom__x-large">
				<div className="footer__block footer__site-menu-container content-width center white-text">
					<ul className="site-menu column mobile-p-around__medium">
						<li className="site-menu-item creme-text">
							<Link to='/home'>{lang['Home']}</Link>
						</li>
						<li className="site-menu-item creme-text">
							<Link to='/aboutus'>{lang['About us']}</Link>
						</li>
						<li className="site-menu-item creme-text">
							<Link to='/faq'>{lang['FAQ']}</Link>
						</li>
						<li className="site-menu-item creme-text">
							<Link to='/contact-us'>{lang['Contact us']}</Link>
						</li>
                        <li className="site-menu-item creme-text">
							{loggedIn ?
								<Link to="/inventory-list">{lang['Surplus/Wanted lists']}</Link> :
								<p style={{margin: '0', display: "inline-block",cursor: "pointer"}} onClick={()=> modal('open')} >{lang['Surplus/Wanted lists']}</p>
							}
						</li>
						{loggedIn &&
							<React.Fragment>
								{/* <li className="site-menu-item creme-text">
									<Link to='/upload'>{lang['Upload']}</Link>
								</li> */}
							</React.Fragment>
						}
					</ul>
					<div className="footer__block footer-social-container float-right column mobile-text--centered">
						<a
							href="https://www.facebook.com/internationalzooservices/"
							className="icon-button icon-button--facebook"
							target="_blank"
							rel="noreferrer"
						></a>
						<a
							href="https://www.instagram.com/zooservices/"
							className="icon-button icon-button--instagram"
							target="_blank"
							rel="noreferrer"
						></a>
						<a
							href="https://www.youtube.com/channel/UCASeZJwxkle8FmL4p2QUv3g"
							className="icon-button icon-button--youtube"
							target="_blank"
							rel="noreferrer"
						></a>
					</div>
				</div>
			</div>
			<div className="row p-top__large p-bottom__large black-background">
				<div className="column size__one-of-one green-text text--centered">
					Copyright &copy; 1997 - {new Date().getFullYear()} International Zoo Services
				</div>
			</div>
			<Modal extraId={'1'}>
				<h3>{lang['Sorry']}</h3>
				<h4>{lang['You need to be registered to access the complete surplus list']}</h4>
				<h4> {lang['If you are not registered, register'] }<Link to='/register' onClick={() => modal('close')} > {lang["here"]}</Link>.</h4>
				<h4>{lang["If you are registered please"]} <Link to='/login' onClick={() => modal('close')} >{lang["log in"]}</Link>.</h4>
				<Button onClick={() => modal('close')} extraClasses="m-top__medium m-right__small center">{lang['Close']}</Button>					
			</Modal>
		</footer>
	)
}

export default Footer
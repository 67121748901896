export const faqs = [
	{
		"title": "Surplus and wanted animals",
		"faqs": [
			{
				"title": "Complete surplus list",
				"q": "I want a complete list of all available specimens; where can I find it?",
				"a": `You need to be registered to access the complete surplus list. If you are not registered, register <a href="/register">here</a>. With our menu you can make selections for mammals, birds or any other species of your interest.`
			},
			{
				"title": "Destination of animals",
				"q": "Should I choose to publish our Institution's surplus animals on IZS website. Will I be informed about the final destination?",
				"a": `Yes, on request we can provide you all details of the future destination, like pictures of the facilities, etc.
					The final destination is subject to the approval from the supplying zoo.`
			},
			{
				"title": "Animals in stock",
				"q": "Does International Zoo Services have animals in stock and acts like a kind of animal-dealer?",
				"a": `IZS has no animals in stock, we provide consultancy and assistance to our contacts. The animals published
					in our website are located in the zoos and breeder's facilities, and they will be transported directly from zoo to zoo.`
			},
			{
				"title": "EXCHANGE OF ANIMALS",
				"q": "Is an exchange of animals possible?",
				"a": `Yes, exchange can be considered, it will depend on whether or not there would be interested zoos for your surplus animals.
						In case there is interest, we have the conditions that your surplus animals are to be shipped first, afterwards we will send
						you the animals you have requested.`
			},
			{
				"title": "SEND YOUR SURPLUS AND WANTED SPECIES",
				"q": "Can I publish my surplus and wanted specimens via International Zoo Services?",
				"a": `Yes, you can send us your surplus by filling in the matrix, or by sending a list by email to following address:
					<a href="mailto:info@zoo-services.com" target="_blank">info@zoo-services.com</a>. Our team will check if the
					surplus is suitable to be mentioned in our website.`
			},
			{
				"title": "REQUESTED SPECIMEN IS NOT ON THE SURPLUS SURVEY",
				"q": "I am looking for a specimen that is not mentioned on the surplus list on the website; can International Zoo Services help me locating?",
				"a": `Yes, we can offer our support by mentioning the concerning specimen in our newsletter to our subscribed contacts. You will
					be informed accordingly in case we can locate.`
			}
		]
	},
	{
		"title": "Permits and other documents",
		"faqs": [
			{
				"title": "Permits",
				"q": "Which permits are necessary for import and export of animals?",
				"a": `This depends on several aspects. First the species: Some species are endangered and are mentioned on the so called
					Cites-list. Cites is the international organization that regulates the trade of endangered species. Cites means: Convention
					of International Trade on Endangered Species. All permits (import and export) need to be applied at your country
					Cites-office. Addresses of the Cites office in your country can be found in <a href="https://www.cites.org/eng/cms/index.php/component/cp" target="_blank">
					https://www.cites.org/eng/cms/index.php/component/cp</a>
					Veterinary aspects are another issue. All countries have their own import conditions for each category of animals. See below.
					Certificate of Origin: sometimes a certificate of origin is required; this can be obtained at the local Chamber of Commerce.
					Other permits: sometimes, a transit-permit is necessary in case the animals make a stop-over at another airport.`
			},
			{
				"title": "CITES-PERMITS",
				"q": "How do I know if a species is on the list of Cites?",
				"a": `There are 3 categories of Cites-species. Regularly updated information can be found at:
					<a href="https://speciesplus.net/" target="_blank">https://speciesplus.net/</a>`
			},
			{
				"title": "CITES PERMITS WITHIN EUROPEAN UNION",
				"q": "Do I need permits for transport within the European Union of animals that are on the Cites A/B/C-list?",
				"a": "Sometimes yes, you need to contact your Cites-office for the last updated information."
			},
			{
				"title": "DURATION OF PERMIT APPLICATION",
				"q": "How long does it take before a Cites export or import permit is issued?",
				"a": `This varies from country to country; in Europe it usually takes around 3-4 weeks for Cites B species.
						For Cites A species it can take little longer. In U.S.A. it takes from 3 weeks to 3 months for Cites II species,
						and for Cites I species from 5 months to a year. In Asia it depends on the country. For complicated applications
						that will need a scientific committee to approve it, issuing time could be longer.`
			},
			{
				"title": "VETERINARY IMPORT CONDITIONS",
				"q": "Where can I get information about the veterinary requerements of importing animals into my country?",
				"a": `You can find information concerning your veterinary department or OIE representatives at:
					<a href="//www.oie.int/en/about-us/our-members/delegates-new/">www.oie.int/en/about-us/our-members/delegates-new/</a>
					By contacting your veterinary department, you can find out your country official veterinary import requirements, in case
					you are the receiver. In case you are the shipper, you can also contact your department to inquire if they can fulfill the
					import conditions from the receiving country.`
			}
		]
	},
	{
		"title": "Financial aspects",
		"faqs": [
			{
				"title": "TERMS OF PAYMENT",
				"q": "Which are the terms of payment of International Zoo Services?",
				"a": `A deposit of 30% is usually required at the moment of reservation, in order to get species reserved
					for the interested institution and apply documents and permits accordingly. For very large orders we can
					consider a reduction on deposit. The balance need to be paid a few days before shipment.`
			},
			{
				"title": "FINANCIAL RELIABILITY OF INTERNATIONAL ZOO SERVICES",
				"q": "Can I trust International Zoo Services with sending my payments before delivery?",
				"a": `International Zoo Services exists since 1985; during this period thousands of transports have been realized and all clients
						trusted us and paid up front. In case we cannot supply the concerning animals because of certain circumstances, deposit-payments
						always have been refunded.`
			}
		]
	},
	{
		"title": "Our clients",
		"faqs": [
			{
				"title": "RELATIONS",
				"q": "Which are International Zoo Services relations, to whom the animals are offered?",
				"a": `Our clients are mainly zoological parks, and aquariums from all over the world, recognized breeding centers that manage
						the animal-collection according to current approved standards. Importers and animal suppliers can also be our
						partners as long as the transactions are handled according our standards and transparency is guaranteed.`
			},
			{
				"title": "LIVE ARRIVAL GUARANTEE",
				"q": "Is there a live arrival guarantee?",
				"a": `We can guarantee live arrival but only in case we can receive a certificate from your veterinary department within 24 hours after
						arriving, stating the mortality. The concerning animals need to be kept untill refund has been realized.`
			},
			{
				"title": "DATE OF DELIVERY",
				"q": "Can a fix date of delivery be agreed?",
				"a": `It is difficult to guarantee delivery within a certain period; on many circumstances out of our complete control.
					Especially when export and import permits are required there can be unexpected on permits issuing time.`
			}
		]
	},
	{
		"title": "Contact",
		"faqs": [
			{
				"title": "PHONE CONTACT",
				"q": "Can I call International Zoo Services by phone?",
				"a": `You can always leave a message at:<br>
				phone/whatsapp: +31854011610<br>
				email: surplus-wanted@zoo-services.com<br>
				Our team will attend your questions and reply back as soon as possible.`
			},
		]
	}
]

export const esFaqs = [
	{
		"title": "Animales excedentes y buscados",
		"faqs": [
			{
				"title": "Listado de excedentes",
				"q": "Deseo una lista completa de todos los especímenes disponibles; ¿Dónde puedo encontrarlo? ",
				"a": `Para acceder al listado completo de excedentes debe registrarse en el sitio, regístrate <a href="/register">aquí</a>. Con nuestro menú puedes realizar selecciones de mamíferos, aves o cualquier otra especie de su interés.`
			},
			{
				"title": "Destino de los animales",
				"q": "Debo optar por publicar los animales excedentes de nuestra Institución en el sitio web de IZS. ¿Seré informado sobre el destino final?",
				"a": `Sí, si usted lo desea, podemos proporcionarle todos los detalles del destino futuro, como imágenes
					de las instalaciones, etc. El destino final está sujeto a la aprobación del zoológico de origen.`
			},
			{
				"title": "Animales en stock",
				"q": " ¿Tiene International Zoo Services animales en existencia y actúa como una especie de vendedor de animales?",
				"a": `IZS no tiene animales en stock, brindamos asesoría y asistencia a nuestros contactos. Los animales publicados
					en nuestro sitio web están ubicados en los zoológicos y las instalaciones de los criadores, y serán transportados
					directamente de un zoológico a otro.`
			},
			{
				"title": "INTERCAMBIO DE ANIMALES",
				"q": "¿Es posible un intercambio de animales? ",
				"a": `Sí, se puede considerar el intercambio, dependerá de si habrá o no zoológicos interesados para sus animales
					excedentes. En caso de que haya interés, tenemos la condición de que sus animales excedentes se envíen
					primero, luego le enviaremos los animales que haya solicitado.`
			},
			{
				"title": "ENVIE SUS ANIMALES DISPONIBLES Y ESPECIES DESEADAS",
				"q": "¿Puedo publicar mis especies excedentes y deseadas a través de International Zoo Services?",
				"a": `Sí, puede enviarnos su listado de especies excedentes llenando la matriz, o enviando una lista por correo electrónico a la siguiente dirección: 
				<a href="mailto:info@zoo-services.com" target="_blank">info@zoo-services.com</a>. Nuestro equipo verificará si el
					excedente es adecuado para ser mencionado en nuestro sitio web.`
			},
			{
				"title": "LA ESPECIE QUE BUSCO NO ESTÁ EN LA LISTA DE ANIMALES DISPONIBLES",
				"q": "Estoy buscando un espécimen que no se menciona en la lista de excedentes del sitio web; ¿Pueden los servicios de International Zoo Servicies ayudarme a localizarlo?",
				"a": `Sí, podemos ofrecer nuestro apoyo mencionando el espécimen en cuestión en nuestro boletín a nuestros contactos suscritos. Le informaremos en cuanto localicemos el espécimen.`
			}
		]
	},
	{
		"title": "Permisos y otros documentos",
		"faqs": [
			{
				"title": "Permisos",
				"q": " ¿Qué permisos son necesarios para la importación y exportación de animales?",
				"a": `Esto depende de varios aspectos. Las especies: algunas especies están en peligro de extinción y se mencionan en la lista de
					CITES. Cites es la organización internacional que regula el comercio de especies en peligro de extinción. CITES significa: Convención de
					Comercio Internacional de Especies en Peligro de Extinción. Todos los permisos (importación y exportación) deben aplicarse en
					la oficina de CITES de su país. Las direcciones de la oficina de CITES en su país se pueden encontrar en
					<a href="https://www.CITES.org/eng/cms/index.php/component/cp" target="_blank">https://www.CITES.org/eng/cms/index.php/component/cp</a>
					Los aspectos veterinarios: cada país tiene sus propias condiciones
					de importación para cada categoría de animales. Vea debbajo. Certificado de origen: a veces se requiere un certificado
					de origen, el cual se puede obtener en la Cámara de Comercio local. Otros permisos: en algunos casos es necesario un permiso
					de tránsito en caso de que los animales hagan una parada en otro aeropuerto.`
			},
			{
				"title": "CITES-PERMISOS",
				"q": " ¿Cómo puedo saber si una especie está en la lista de CITES? ",
				"a": `Hay 3 categorías de CITES-especies. Se puede encontrar información actualizada periódicamente en:
				<a href="https://speciesplus.net/" target="_blank">https://speciesplus.net/</a>`
			},
			{
				"title": "Permisos CITES en la Unión Europea",
				"q": " ¿Necesito permisos para el transporte dentro de la Unión Europea de animales que están en la lista CITES A / B / C? ",
				"a": "A veces, sí, debe ponerse en contacto con su oficina de CITES para obtener información actualizada."
			},
			{
				"title": "Comisión de importación",
				"q": " Soy un importador: ¿Puedo obtener una reducción en los precios del espécimen disponible de International Zoo Services? ",
				"a": `En caso de que usted no sea el destino final sino una institución que maneje las actividades de importación. En ciertos casos,
					podemos considerar ofrecer una reducción como compensación por su apoyo.`
			},
			{
				"title": "CONDICIONES DE IMPORTACION VETERINARIAS",
				"q": "¿Dónde puedo obtener información sobre los requisitos veterinarios para importar animales a mi país? ",
				"a": `Puede encontrar información sobre su departamento veterinario o representantes de la OIE en:
					<a href="//www.oie.int/en/about-us/our-members/delegates-new/">www.oie.int/en/about-us/our-members/delegates-new/</a>
					Aquí puede encontrar los detalles del departamento veterinario de su país, en caso de que usted sea el remitente,
					también puede contactar a su departamento para investigar si puede cumplir con las condiciones de importación del país receptor.`
			}
		]
	},
	{
		"title": "Aspectos financieros",
		"faqs": [
			{
				"title": "Términos de pago",
				"q": "¿Cuáles son las condiciones de pago de International Zoo Services? ",
				"a": `Por lo general, se requiere un depósito del 30% en el momento de la reserva, con el fin de obtener especies
					reservadas para la institución interesada y solicitar los documentos y permisos correspondientes. Para pedidos
					muy grandes, podemos considerar una reducción en el depósito. El saldo debe pagarse unos días antes del envío.`
			},
			{
				"title": "FIABILIDAD FINANCIERA DE LOS SERVICIOS INTERNACIONALES DEL ZOO",
				"q": "¿Puedo confiar en International Zoo Services para pagar depósito por adelantado?",
				"a": `International Zoo Services existe desde 1985; durante este período en el que nuestra organización existe, se han
					realizado miles de transportes y todos los clientes han confiado en nosotros y han pagado por adelantado. En caso de que
					no podamos suministrar los animales correspondientes debido a ciertas circunstancias, los pagos de depósito siempre serán
					reembolsados.`
			}
		]
	},
	{
		"title": "Nuestras clientes",
		"faqs": [
			{
				"title": "RELACIONES",
				"q": "¿Cuáles son las relaciones de International Zoo Services, a quiénes se ofrecerían los animales?",
				"a": `Nuestros clientes son principalmente parques zoológicos y acuarios de todo el mundo, centros de cría
					reconocidos que gestionan la colección de animales según las normas aprobadas vigentes. Los importadores y
					proveedores de animales también pueden ser nuestros socios siempre que las transacciones se realicen de
					acuerdo con nuestros estándares y se garantice la transparencia.`
			},
			{
				"title": "GARANTÍA DE LLEGADA VIVO",
				"q": "¿Hay una garantía de llegada en vivo?",
				"a": `Podemos garantizar la llegada vivo, pero solo en el caso de que podamos recibir un certificado
					de su departamento veterinario dentro de las 24 horas posteriores a la llegada, indicando la mortalidad
					si sucediera. Los animales concernientes deben conservarse hasta que se haya realizado el reembolso.`
			},
			{
				"title": "FECHA DE ENTREGA",
				"q": "¿Hay alguna garantía sobre la fecha de entrega?",
				"a": `Es difícil garantizar la entrega dentro de un período determinado; en muchas circunstancias fuera de
					nuestro completo control. Especialmente cuando se requieren permisos de exportación e importación, puede
					haber imprevistos en el momento de la emisión de permisos.`
			}
		]
	},
	{
		"title": "Contacto",
		"faqs": [
			{
				"title": "CONTACTO TELEFONICO",
				"q": "¿Puedo llamar a International Zoo Services?",
				"a": `Siempre puedes dejar un mensaje en:<br>
				teléfono/whatsapp: +31854011610,<br>
				correo: surplus-wanted@zoo-services.com<br>
				Nuestro equipo atenderá sus preguntas y le responderá lo antes posible.`
			},
		]
	}
]
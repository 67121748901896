export const projects = [
	{"rowId": 1, "cells": [
		{
			"image": "/images/project1.jpg",
			"title": "Penguins"
		},
		{
			"image": "/images/projects-3.jpg",
			"title": "Giraffes"
		}
	]},
	{"rowId": 2, "cells": [
		{
			"image": "/images/project2.jpg",
			"title": "King-cheetahs"
		},
		{
			"image": "/images/projects-5.jpg",
			"title": "Giant tortoises"
		}
	]},
	{"rowId": 3, "cells": [
		{
			"image": "/images/FOTO-100.jpg",
			"title": "Asian Elephants"
		},
		{
			"image": "/images/projects-8.jpg",
			"title": "Gang-gang cockatoos"
		}
	]},
	{"rowId": 4, "cells": [
		{
			"image": "/images/ternera.jpg",
			"title": "Bongo"
		},
		{
			"image": "/images/leopards-300x207.jpg",
			"title": "Clouded leopards"
		}
	]},
	{"rowId": 5, "cells": [
		{
			"image": "/images/manatee.jpg",
			"title": "Manatees"
		},
		{
			"image": "/images/rino.jpg",
			"title": "Indian rhinos"
		}
	]},
	{"rowId": 6, "cells": [
		{
			"image": "/images/lion.jpg",
			"title": "White lions"
		},
		{
			"image": "/images/transport1.jpg",
			"title": "Night Safari"
		}
	]},
	{"rowId": 7, "cells": [
		{
			"image": "/images/project-orangutan.jpg",
			"title": "Orangutan"
		},
		{
			"image": "/images/project-otters.jpg",
			"title": "Otters"
		}
	]},
	{"rowId": 8, "cells": [
		{
			"image": "/images/project_whitetiger.jpg",
			"title": "White Tigers"
		},
		{
			"image": "/images/project-sealion.jpg",
			"title": "Sea Lions"
		}
	]}
]
import React, {useState} from 'react'

import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import Button from '../components/Button'

import {en, es} from '../languages/langs'
import {api, sanctum} from '../services/api'


const ResetPasswordPage = ({setLoggedInState, loggedIn, activeLang, activeUser}) => {
	const lang = {en, es}[activeLang]

	const doResetPassword = async () => {
		const passwd = document.getElementById('password').value
		const passwdRpt = document.getElementById('repeat-password').value
		const qparams = new URLSearchParams(window.location.search)
		if (passwd === '' || passwdRpt === '') {
			alert(lang['Please enter a password and repeat it'])
			return
		}
		if (passwd !== passwdRpt) {
			alert(lang['Passwords don\'t match'])
			return
		}
		await sanctum.get('/csrf-cookie')
		const response = await api.post('reset-password', {
			email: qparams.get('email'),
			token: qparams.get('token'),
			password: passwd,
			password_confirmation: passwdRpt
		})
		if (response.status === 200) {
			alert(lang['Password successfully reset'])
			window.location = '/login'
		} else {
			alert(lang['Sorry, something went wrong'])
		}
	}

	return (
		<React.Fragment>
 
			<Section extraClasses="content-width center mobile-p-left__large mobile-p-right__large">
				<Row>
					<Column extraClasses="size__one-of-one text--centered">
						<h3 className="m-top__large m-bottom__small">{lang['Password reset']}</h3>
						<span className="sub-title sub-title--follows-h3 browngrey-text green-text">
							{lang['Enter your password below and confirm']}
						</span>
					</Column>
				</Row>
				<Row extraClasses="m-top__medium">
					<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
					<Column extraClasses="size__one-of-two m-bottom__large">
						<input
							type="password"
							className="login-form__input login-form__input--mandatory"
							placeholder={`${lang['Password']}*`}
							id="password"
						/>
					</Column>
					<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
				</Row>
				<Row>
					<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
					<Column extraClasses="size__one-of-two m-bottom__x-large">
						<input
							type="password"
							className="login-form__input login-form__input--mandatory"
							placeholder={`${lang['Repeat password']}*`}
							id="repeat-password"
						/>
					</Column>
					<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
				</Row>
				<Row>
					<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
					<Column extraClasses="size__one-of-two m-bottom__x-large">
						<Button onClick={doResetPassword} extraClasses="m-right__small">{lang['Update']}</Button>
					</Column>
					<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
				</Row>
			</Section>
		</React.Fragment>	
	)
}

export default ResetPasswordPage
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import CatalogItem from '../components/CatalogItem'
import Loader from '../components/Loader'

import {speciesMenuSkeleton} from '../data/speciesMenuSkeleton'
import {en, es} from '../languages/langs'
import {api, base} from '../services/api'

const CatalogPage = ({activeLang, orderNames, route, updateActiveRoute}) => {
	const lang = {en, es}[activeLang]

	const [specimensOnPage, setSpecimensOnPage] = useState([<Loader key="loader" />])
	const [pageHeaderInfo, setPageHeaderInfo] = useState({"icon": "", "title": "", "bg": ""})
	const [activePage, setActivePage] = useState(1)
	const [availablePages, setAvailablePages] = useState(0)
	const [activeSorting, setSorting] = useState({type: 'updated_at', dir: 'asc'})

	const updateActivePage = function(data) {
		setActivePage(data.selected + 1)
		if (this.containerClassName.indexOf("second-pagination") !== -1) {
			const topOfContent = document.getElementById("content-start-position").getBoundingClientRect().top
			console.log(topOfContent)
			window.scrollTo(0, (topOfContent + window.scrollY - 20))
		}
	}

	const updateSorting = () => {
		const sorting = [...document.getElementById('sortselect').getElementsByTagName('option')].find(option => option.selected).value
		const type = sorting.includes('cname') ? 'common_name' : sorting.includes('sname') ? 'scientific_name' : sorting
		const dir = sorting.includes('za') ? 'desc' : 'asc'
		setSorting({type, dir})
	}

	useEffect(() => {
		const slug = window.location.pathname.substring(1)
		const slugParts = slug.split('/')
		const menuItem = speciesMenuSkeleton.find(item => item.apiName === slugParts[0])
		let thisEntry = {}

		const renderPageHeader = async () => {
			if (orderNames[slugParts[1]] === undefined) {
				const response = await api.get(`/get-class-menu?class=${slugParts[0]}`)
				const entries = response.data
				thisEntry = entries.find(entry => {
					return entry.common_name_slug === slugParts[1]
				})
			} else {
				thisEntry = orderNames[slugParts[1]]
			}
			thisEntry = thisEntry === undefined ? menuItem : thisEntry
			setPageHeaderInfo({
				"className": activeLang === 'en' ? menuItem.menuTitle : menuItem.menuTitleEs,
				"title": activeLang === 'en' ? thisEntry.common_name || thisEntry.menuTitle : thisEntry.common_name_spanish || thisEntry.menuTitleEs,
				"icon": slugParts[0],
				"bg": menuItem.bg,
				"classLink": slugParts[0]
			})
		}
		renderPageHeader()
	}, [activeLang, route, orderNames])

	useEffect(() => {
		const getSpecimensForOrder = async () => {
			setSpecimensOnPage([<Loader key="loader" extraClasses="m-bottom__xx-huge" />])
			const startAt = activePage - 1
			const slug = window.location.pathname.substring(1)
			const endPoint = slug !== 'all' ?
				`/available-animals-by-order?slug=${slug}&startat=${startAt}&sorting=${activeSorting.type}&lang=${activeLang}&direction=${activeSorting.dir}` :
				`/available-animals?startat=${startAt}&sorting=${activeSorting.type}&lang=${activeLang}&direction=${activeSorting.dir}`

			const response = await api.get(endPoint)
			setAvailablePages(response.data[0] === undefined ? 1 : response.data[0].total_specimens / 24)
			const renderedSpecimenRows = response.data.map(specimen => {
				return (
					<Column key={specimen.id} extraClasses="size__one-of-three m-bottom__xx-large">
						<CatalogItem
							specimen={specimen}
							updateActiveRoute={updateActiveRoute}
							setActiveCatalogPage={setActivePage}
							activeLang={activeLang}
						/>
					</Column>
				)
			})
			const renderedSpecimens = () => {
				return (
					<Row extraClasses="m-top__large">
						{renderedSpecimenRows.length > 0 &&
							renderedSpecimenRows
						}
						{renderedSpecimenRows.length === 0 &&
							<Row extraClasses="m-top__large m-bottom__large">
								<Column extraClasses="size__one-of-one text--centered">
									<h3 className="green-text">{lang["We're sorry, there are no results"]}</h3>
								</Column>
							</Row>
						}
					</Row>
				)
			}
			setSpecimensOnPage(renderedSpecimens)
		}
		getSpecimensForOrder()
	},[activeLang, updateActiveRoute, activePage, lang, activeSorting])

	useEffect(() => {
		setActivePage(1)
	}, [route])

	return (
		<React.Fragment>
			<PageHeader
				showTitle={false}
				image={`https://${base}/header_images${window.location.pathname}.jpg`}
				bgPos="50% 50%"
				highVersion={true}
				extraClasses="content-width center p-left__x-large"
			>
				<div className="page-image-header__title content-width center p-top__x-large text-shadow--large">
					<div className="page-image-header__title-icon">
						<i className={`icon ${pageHeaderInfo.icon}`}></i>
					</div>
					<h1 className="creme-text m-bottom__none">{pageHeaderInfo.title}</h1>
				</div>
				<div className="content-width center page-image-header__breadcrumbs m-top__x-large creme-text p-bottom__large text-shadow">
					<Link to="/" className="page-image-header__breadcrumb creme-text">Home</Link>
					<span className="page-image-header__breadcrumb-sep">&gt;&gt;</span>
					<Link
						to={`/${pageHeaderInfo.classLink}`}
						className="page-image-header__breadcrumb creme-text"
						onClick={() => {updateActiveRoute(pageHeaderInfo.classLink)}}
					>
							{pageHeaderInfo.className}
					</Link>
					{window.location.pathname.substring(1).split('/').length === 2 &&
						<React.Fragment>
							<span className="page-image-header__breadcrumb-sep">&gt;&gt;</span>
							<span
								className="page-image-header__breadcrumb creme-text"
							>
									{pageHeaderInfo.title}
							</span>
						</React.Fragment>
					}
				</div>
			</PageHeader>
			<Section extraClasses="content-width center p-top__x-large p-bottom__large mobile-p-left__medium mobile-p-right__medium">
				<Row>
					<div id="content-start-position"></div>
					<Column extraClasses="size__one-of-two">
						<b>c.b.</b>= {lang['captive bred']}, <b>w.c.</b>= {lang['wild caught']}
					</Column>
					<Column extraClasses="size__one-of-two">
						<Row>
							<Column extraClasses="size__eight-of-twelve">
								<div className="pagination mobile-no-center mobile-m-top__medium mobile-m-bottom__medium">
									{availablePages > 1 &&
										<ReactPaginate
											previousLabel="&larr;"
											nextLabel="&rarr;"
											breakLabel="..."
											breakClassName="break-me"
											pageCount={availablePages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={updateActivePage}
											containerClassName="pagination__pages"
											subContainerClassName="pages pagination"
											activeClassName="active"
											forcePage={activePage - 1}
										/>
									}
								</div>
							</Column>
							<Column extraClasses="size__four-of-twelve mobile-size__one-of-one float-right">
								<div className="float-right mobile-size__one-of-one">
									<select name="" id="sortselect" className="select--green mobile-size__one-of-one" onChange={updateSorting}>
										<option value="updated_at">{lang['Last modified']}</option>
										<option value="code_number">{lang['Taxonomy']}</option>
										<option value="cname-az">{lang['Common name (A-Z)']}</option>
										<option value="cname-za">{lang['Common name (Z-A)']}</option>
										<option value="sname-az">{lang['Scientific name (A-Z)']}</option>
										<option value="sname-za">{lang['Scientific name (Z-A)']}</option>
									</select>
								</div>
							</Column>
						</Row>
					</Column>
				</Row>
			</Section>
			<Section extraClasses="content-width center">
				{specimensOnPage}
			</Section>
			<Section extraClasses="content-width center p-top__x-large p-bottom__large mobile-p-left__medium mobile-p-right__medium">
				<Row>
					<Column extraClasses="size__one-of-one">
						<div className="pagination mobile-no-center mobile-m-top__medium mobile-m-bottom__medium float-right">
							{availablePages > 1 &&
								<ReactPaginate
									previousLabel="&larr;"
									nextLabel="&rarr;"
									breakLabel="..."
									breakClassName="break-me"
									pageCount={availablePages}
									marginPagesDisplayed={2}
									pageRangeDisplayed={3}
									onPageChange={updateActivePage}
									containerClassName="pagination__pages second-pagination"
									subContainerClassName="pages pagination"
									activeClassName="active"
									forcePage={activePage - 1}
								/>
							}
						</div>
					</Column>
				</Row>
			</Section>
		</React.Fragment>
	)
}

export default CatalogPage
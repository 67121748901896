const checkArrowEntries = [
	{
		link: '/aboutus#howwework',
		label: 'Location-service: how we work'
	},
	{
		link: '/aboutus#transport',
		label: 'Transport and documentation-service: work for specialists'
	},
	{
		link: '/aboutus#breedingloan',
		label: 'Breeding-loan and donations: especially for eaza or aza-members'
	},
	{
		link: '/aboutus#exchange',
		label: 'Exchange'
	},
	{
		link: '/aboutus#specialprojects',
		label: 'Special projects: donations from government to government'
	}
]

export default checkArrowEntries
import React, { useEffect, useState } from 'react'

import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import ImageCard from '../components/ImageCard'
import Button from '../components/Button'
import PageHeader from '../components/PageHeader'
import Loader from '../components/Loader'
import Modal from '../components/Modal'

import {api} from '../services/api'
import {en, es} from '../languages/langs'

const InquiryPage = ({userId, activeLang, setRequestedSurplusIds, requestedSurplusIds, loggedIn}) => {
	const lang = {en, es}[activeLang]
	const [submited, setSubmited] = useState(false)

	const [animalsOnPage, setAnimalsOnPage] = useState([])
    
	function modal(state) {
		let m = document.getElementById('modal6')
		switch(state){
			case 'close':
				m.style.display = 'none';
				break
			case 'open': 
			    m.style.display = 'block';
				break
			default:
			    break
		}
    }

	const submitInquiry = async () => {
		let okToSubmit = true
		const payload = [...document.querySelectorAll('.animal-details')].map( animalDetails => {
			const ret = {
				surplus_id:     animalDetails.className.match(/data-animal-id__([0-9]{1,})/)[1],
				quantityM:      0,
				quantityF:      0,
				quantityU:      0,
				client_remarks: animalDetails.querySelector('.inquiry-req__remarks').value
			}
			const inputs = Array.from(animalDetails.getElementsByTagName('input'))
			let success = false;
			inputs.forEach( input => {
				if (isNaN(Number(input.value)) && input.classList.contains('should-be-number')) {
					alert('Please insert a valid number in the quantity boxes')
					okToSubmit = false
					return
				}				
				if (input.value.length > 0) {					
					success = true					
				}
				ret[`quantity${input.getAttribute('data-type')}`] = Number(input.value)
			})
			if(!success){
				alert('You must insert the quantities of the query')
				okToSubmit = false				
				return			
			}
			return ret
		})

		if (!okToSubmit) {
			return
		}

		try {
			const response = await api.post(`/create-request`, {
				userId,
				surplusses: payload
			})
			if (response.data.success) {
				setSubmited(true)
				modal('open')
			}
		} catch (e) {
			modal('open')
			return
		}
	}
    
	useEffect(() => {
		
		setAnimalsOnPage([<Loader key="loader" extraClasses="m-bottom__xx-huge" />])

		const removeAnimalFromInquiryList = id => {
			const currentIds = sessionStorage.getItem('requestedSurplusIds').split(',')
			currentIds.splice(currentIds.indexOf(id.toString()), 1)
			if (currentIds.length === 0) {
				sessionStorage.removeItem('requestedSurplusIds')
				setRequestedSurplusIds(null)
				return
			}
			sessionStorage.setItem('requestedSurplusIds', currentIds)
			setRequestedSurplusIds(currentIds.toString())
			alert(lang['Animal is removed from your inquiry'])
		}
	
		const getAnimals = async () => {
			const requestedAnimals = sessionStorage.getItem('requestedSurplusIds') ? sessionStorage.getItem('requestedSurplusIds').split(',') : []
			const renderedInquiries = await Promise.all(requestedAnimals.map(async animalId => {
					const response = await api.get(`/surplus-by-id?surplus_id=${animalId}`)
					const animal = response.data
					const coverImage = animal.images.find(image => image.is_cover_new_website) || animal.images.find(image => image.is_cover_old_website)
					return (
						<ImageCard
							type="horizontal"
							width="ten-of-twelve"
							activeLang={activeLang}
							image={coverImage.src}
							title={animal.common_name}
							key={animalId}
							extraClasses="m-top__x-large"
						>
							<Row>
								<Column extraClasses={`size__one-of-one animal-details data-animal-id__${animalId}`}>
									<Row extraClasses="m-top__small">
										<Column extraClasses="size__one-of-one">
											<span className="line-height__large p-bottom__large">
												{animal.located_at}
											</span>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium inquiry-details">
										<Column className="size__one-of-one avail-table">
											<div className="row">
												<div className="column row p-right__medium mobile-column--autowidth">
													<div className="column size__one-of-one green-text avail-table__header">{lang['Types']}:</div>
													<div className="column size__one-of-one">{lang['Requested']}:</div>
												</div>
												<div className="column row p-right__medium mobile-column--autowidth">
													<div className="column size__one-of-one green-text avail-table__header">M</div>
													<div className="column size__one-of-one">
														<input type="text" data-type="M" className="inquiry-req__male inquiry-input should-be-number" style={{"width": "3rem"}} />
													</div>
												</div>
												<div className="column row p-right__medium mobile-column--autowidth">
													<div className="column size__one-of-one green-text avail-table__header">F</div>
													<div className="column size__one-of-one">
														<input type="text" data-type="F" className="inquiry-req__female inquiry-input should-be-number" style={{"width": "3rem"}} />
													</div>
												</div>
												<div className="column row p-right__medium mobile-column--autowidth">
													<div className="column size__one-of-one green-text avail-table__header">U</div>
													<div className="column size__one-of-one">
														<input type="text" data-type="U" className="inquiry-req__unknown inquiry-input should-be-number" style={{"width": "3rem"}} />
													</div>
												</div>
											</div>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-one">
											<textarea className="inquiry-input inquiry-req__remarks" placeholder="Remarks" rows="4" style={{"width": "100%"}}></textarea>
										</Column>
									</Row>
									<Row>
										<Column extraClasses="size__one-of-one m-top__medium">
											<Button extraClasses="m-left__small button--inverse" onClick={() => removeAnimalFromInquiryList(animal.available.surplus_id)}>
												{lang['Remove']}
											</Button>
										</Column>
									</Row>
								</Column>
							</Row>
						</ImageCard>
					)
				})
			)

			setAnimalsOnPage(renderedInquiries)
			
		}
		getAnimals()
	}, [requestedSurplusIds, activeLang, setRequestedSurplusIds, lang])

	return (
		<React.Fragment>
			{loggedIn === true &&
			<React.Fragment>
				<PageHeader titleKey="Your inquiry" image="images/papegaai.jpg" bgPos="60% 50%" activeLang={activeLang} />
				<Section extraClasses="content-width center">
					<Row>
						<Column extraClasses="size__one-of-one m-top__large">
							<b>M</b>= {lang['Male']}, <b>F</b>= {lang['Female']}, <b>U</b>= {lang['Sex is unknown']}, <b>Pr</b>= {lang['Pair']}
						</Column>
					</Row>
					<Row>
						<Column extraClasses="size__one-of-one">
							{animalsOnPage.length === 0 &&
								<h3 className="text--centered m-top__x-large">{lang['no_animals_added']}</h3>
							}
							{animalsOnPage}
						</Column>
					</Row>
				</Section>
				{animalsOnPage.length > 0 &&
					<Section extraClasses="content-width center m-top__x-large m-bottom__x-large">
						<Row>
							<Button onClick={submitInquiry} extraClasses="float-right">{lang['Submit your inquiry']}</Button>
						</Row>
					</Section>
				}
			</React.Fragment>
			}
			{!loggedIn &&
				<React.Fragment>
					<Section extraClasses="content-width center text--centered m-top__large m-bottom__large">
						<h4>{lang['loginwarn_inquiry']}</h4>
						<Button link="/login">{lang['Login']}</Button>
					</Section>
				</React.Fragment>
			}
			<Modal extraId={'6'}>
				{submited ? 
				  <>
				    <h3>{lang['Submited']}</h3>
				    <h4>{lang['Your inquiry sent']}</h4>
				    <h4> {lang['More info']}</h4>
				    <Button onClick={() => modal('close')} extraClasses="m-top__medium m-right__small center">{lang['Close']}</Button>
				  </>				  
				  : 
				  <>
				    <h3>{lang['Sorry, something went wrong']}</h3>
				    <h4>{lang['Problem inquiry']}</h4>
				    <h4> {lang['Contact us for support']} <a target="_blank" href="mailto:info@zoo-services.com"> info@zoo-services.com</a> </h4>
				    <Button onClick={() => modal('close')} extraClasses="m-top__medium m-right__small center">{lang['Close']}</Button>
				  </>
				}
			</Modal>
		</React.Fragment>		
	)
}

export default InquiryPage
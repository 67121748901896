import axios from "axios";

const host = 'app'
export const base = `${host}.zoo-services.com`

const UNAUTHORIZED = 401;

export const api = axios.create({
	baseURL: `https://${base}/api`,
	withCredentials: true,
});

export const sanctum = axios.create({
	baseURL: `https://${base}/sanctum`,
})

const accessToken = window.localStorage.getItem("accessToken");

if(accessToken){
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

	api.interceptors.response.use((response) => {      
		if(response.data.status === 'error' &&  response.data.cant.length !== 0){
			localStorage.removeItem("accessToken");
			delete axios.defaults.headers.common.Authorization;
			localStorage.setItem("messageError", 'true');
			window.location.href = "/login";
		}else{
			return response;
		}
	},(error) => {
		const {status} = error.response;
		if (status === UNAUTHORIZED) {
			if( window.location.pathname === "/login"){
				console.log("Signin");
			}
			else{
				window.location.href = "login";
			}
		}
		return Promise.reject(error);
	})}
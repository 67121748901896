import React, { useEffect, useState } from 'react'

import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import SummaryTile from '../components/SummaryTile'
import Modal from '../components/Modal'
import Button from '../components/Button'
import ImageButton from '../components/ImageButton'

import {es, en} from '../languages/langs'
import {api} from '../services/api'

const ProductPage = ({updateActiveRoute, activeLang, updateRequestedSurplusIds}) => {
	const lang = {en, es}[activeLang]

	const [activeAnimal, setActiveAnimal] = useState({
		"common_name": "Loading",
		"scientific_name": "Loading",
		"spanish_name": "Loading",
		"available": {
			"male": "Loading",
			"female": "Loading",
			"unknown": "Loading",
			"pair": "Loading"
		},
		"description": "Loading",
		"images": [
			{"src": ""},
			{"src": ""},
			{"src": ""},
			{"src": ""},
			{"src": ""},
			{"src": ""},
			{"src": ""}
		],
		"coverImage": "",
		"surplusses": [],
		"related_surplusses": []
	})
	const [mainImage, setMainImage] = useState('')

	useEffect(() => {
		const addSurplusToInquiry = id => {
			updateRequestedSurplusIds(id)
			modal('open')
		}

		(async () => {
			const [root, className, order, specimen] = window.location.pathname.split('/')
			const response = await api.get(`/surplus-by-slug?slug=${order}/${specimen}`)
			const animal = response.data
			animal.coverImage = animal.images.find(image => image.is_cover_new_website) || animal.images.find(image => image.is_cover_old_website)
			animal.coverImage = !animal.coverImage ? '' : animal.coverImage
			response.data.surplusses.unshift({
				"available": response.data.available,
				"description": response.data.description,
				"located_at": response.data.located_at
			})			
			animal.surplusses = response.data.surplusses.map(surplus => {
				return (
					<SummaryTile
						translate={false}
						subTitle={surplus.located_at}
						showButton={false}
						description={surplus.description}
						buttonTextKey="Apply for an offer"
						activeLang={activeLang}
						key={surplus.available.surplus_id}
						extraClasses="m-top__large m-bottom__xx-large mobile-m-bottom__small mobile-m-top__xx-large "
					>
						<Row extraClasses="m-top__small">
							<Column extraClasses="size__one-of-one">
								<Button onClick={() => {addSurplusToInquiry(surplus.available.surplus_id)}}>{lang['Apply for an offer']}</Button>
							</Column>
						</Row>
					</SummaryTile>
				)
			})
			animal.related_surplusses = response.data.related_surplus.map((surplus, i) => {				
				if (i < 6) {
					var paramUrl = "";
					if(surplus.class_common_name_slug == "reptiles"){
						paramUrl = "reptiles-amphibians";
					}else{
						paramUrl = surplus.class_common_name_slug;
					}
					return (
						<Column key={surplus.surplus_id} extraClasses="size__one-of-six p-right__x-large">
							<ImageButton
								image={surplus.img_src}
								link={`/${paramUrl}/${surplus.order_common_name_slug}/${surplus.scientific_name_slug}`}
								activeLang={activeLang}
								text={surplus.common_name}
								spanishText={animal.spanish_name}
								updateActiveRoute={updateActiveRoute}
							/>
						</Column>
					)
				}
			})
			setActiveAnimal(animal)
			setMainImage(animal.coverImage.src)
		})()
	}, [activeLang, lang, updateRequestedSurplusIds, updateActiveRoute])

	function modal(state) {
		let m = document.getElementById('modal4')
		m.style.display = state === 'open' ? 'block' : 'none'
	}

	return (
		<React.Fragment>
			<Section extraClasses="content-width center p-top__x-large p-bottom__x-large mobile-p-left__medium mobile-p-right__medium">
				<b>c.b.</b>= {lang['captive bred']}, <b>w.c.</b>= {lang['wild caught']}
			</Section>
			<Section extraClasses="content-width center">
				<Row>
					<Column extraClasses="size__one-of-three mobile-p-right__medium mobile-p-left__medium">
						<div className="image-gallery">
							<div className="row">
								<div className="column image-gallery__main square-container">
									<div className="image-gallery__main-image-frame p-around__small image-gallery--has-shadow">
										<div className="image-gallery__main-image" style={{backgroundImage: `url('${mainImage}')`}}></div>
									</div>
								</div>
							</div>
							<div className="row p-top__medium image-gallery__thumbrow">
								{activeAnimal.images[1] !== undefined &&
									<div className="column size__one-of-three mobile-size__one-of-three cursor--pointer" onClick={() => {setMainImage(activeAnimal.images[1].src)}}>
										<div className="square-container image-gallery__thumb-holder">
											<div className="image-gallery__thumb-frame p-around__small image-gallery--has-shadow">
												<div className="image-gallery__thumb" style={{backgroundImage: `url('${activeAnimal.images[1].src}')`}}></div>
											</div>
										</div>
									</div>
								}
								{activeAnimal.images[2] !== undefined &&
								<div className="column size__one-of-three mobile-size__one-of-three cursor--pointer" onClick={() => {setMainImage(activeAnimal.images[2].src)}}>
									<div className="square-container image-gallery__thumb-holder">
										<div className="image-gallery__thumb-frame p-around__small image-gallery--has-shadow">
											<div className="image-gallery__thumb" style={{backgroundImage: `url('${activeAnimal.images[2].src}')`}}></div>
										</div>
									</div>
								</div>
								}
								{activeAnimal.images[4] !== undefined &&
								<div className="column size__one-of-three mobile-size__one-of-three cursor--pointer" onClick={() => {setMainImage(activeAnimal.images[3].src)}}>
									<div className="square-container image-gallery__thumb-holder">
										<div className="image-gallery__thumb-frame p-around__small image-gallery--has-shadow">
											<div className="image-gallery__thumb" style={{backgroundImage: `url('${activeAnimal.images[3].src}')`}}></div>
										</div>
									</div>
								</div>
								}
							</div>
							<div className="row p-top__medium image-gallery__thumbrow">
								{activeAnimal.images[4] !== undefined &&
								<div className="column size__one-of-three mobile-size__one-of-three cursor--pointer" onClick={() => {setMainImage(activeAnimal.images[4].src)}}>
									<div className="square-container image-gallery__thumb-holder">
										<div className="image-gallery__thumb-frame p-around__small image-gallery--has-shadow">
											<div className="image-gallery__thumb" style={{backgroundImage: `url('${activeAnimal.images[4].src}')`}}></div>
										</div>
									</div>
								</div>
								}
								{activeAnimal.images[5] !== undefined &&
								<div className="column size__one-of-three mobile-size__one-of-three cursor--pointer" onClick={() => {setMainImage(activeAnimal.images[5].src)}}>
									<div className="square-container image-gallery__thumb-holder">
										<div className="image-gallery__thumb-frame p-around__small image-gallery--has-shadow">
											<div className="image-gallery__thumb" style={{backgroundImage: `url('${activeAnimal.images[5].src}')`}}></div>
										</div>
									</div>
								</div>
								}
								{activeAnimal.images[6] !== undefined &&
								<div className="column size__one-of-three mobile-size__one-of-three cursor--pointer" onClick={() => {setMainImage(activeAnimal.images[6].src)}}>
									<div className="square-container image-gallery__thumb-holder">
										<div className="image-gallery__thumb-frame p-around__small image-gallery--has-shadow">
											<div className="image-gallery__thumb" style={{backgroundImage: `url('${activeAnimal.images[6].src}')`}}></div>
										</div>
									</div>
								</div>
								}
							</div>
						</div>
					</Column>
					<Column extraClasses="size__two-of-three p-left__x-large mobile-p-left__medium mobile-p-right__medium mobile-m-top__large">
						<Row>
							<Column extraClasses="size__one-of-one">
								<h3 className="green-text summary-tile__title normalcase m-bottom__none">
									{activeAnimal.common_name + ' (' + activeAnimal.scientific_name + ')'}
								</h3>
							</Column>
						</Row>
						<Row extraClasses="float-right">
							<Column extraClasses="column size__one-of-one text--right mobile-text--left m-top__medium">
								<a
									href={`https://en.wikipedia.org/wiki/${activeAnimal.scientific_name}`}
									target="_blank"
									rel="noreferrer"
									className="button button--inverse mobile-m-top__small m-right__small">Wikipedia</a>
								<a
									href={`https://www.google.com/search?q=${activeAnimal.scientific_name}&biw=1920&bih=1069&tbm=isch`}
									target="_blank"
									rel="noreferrer"
									className="button button--inverse m-right__small mobile-m-top__small">Google images</a>
								<a
									href={`https://speciesplus.net/species#/taxon_concepts?taxon_concept_query=${activeAnimal.scientific_name}`}
									target="_blank"
									rel="noreferrer"
									className="button button--inverse mobile-m-top__small">SpeciesPlus</a>
							</Column>
						</Row>
						{activeAnimal.surplusses}
					</Column>
				</Row>
			</Section>
			<hr className="creme-divider content-width center m-top__x-large" />
			<Section extraClasses="content-width center m-top__large">
				<Row extraClasses="row content-width center mobile-p-left__large mobile-p-right__large">
					<Column>
						<h3 className="green-text  m-bottom__none">{lang['Related animals']}</h3>
						{/* <span className="sub-title sub-title--follows-h3 browngrey-text">Space for a sub-title</span> */}
					</Column>
				</Row>
				<Row extraClasses="content-width center m-top__large mobile-p-left__large mobile-p-right__large mobile-layout--hor-scroll">
					{activeAnimal.related_surplusses}
				</Row>
			</Section>
			<PageHeader extraClasses="m-top__xx-large" titleKey="Our worldwide network" image="/images/papegaai.jpg" bgPos="60% 50%" activeLang={activeLang}>
				<Button link="/contact" extraClasses="m-top__large">{lang['Contact us']}</Button>
			</PageHeader>
			<Modal extraId={'4'}>
					<h5 className="browngray-text m-top__none">{lang['The following specimen']}</h5>
						<h4 className="green-text m-bottom__none">
							{lang['Availability']}: {activeAnimal.available.option}<br />
							{activeAnimal.common_name + ' (' + activeAnimal.scientific_name + ')'}
						</h4>
						<span>{activeAnimal.located_at}</span>
						<Row>
							<Button onClick={() => {modal('close')}} extraClasses="m-top__medium m-right__small">{lang['closepanel']}</Button>
							<Button link="/inquiry-list" extraClasses="m-top__medium">{lang['gotoinquirypage']}</Button>
						</Row>
				</Modal>
		</React.Fragment>
	)
}

export default ProductPage

import React from 'react'

import {Link} from 'react-router-dom'

const ImageButton = props => {
	const getText = () => {
		if (props.activeLang === 'en') {
			return props.text
		} else {
			return props.spanishText
		}
	}

	const doCallback = () => {
		if (props.updateActiveRoute !== undefined) {
			window.scrollTo(0, 0)
			props.updateActiveRoute(props.link)
		}
	}
	return (
		<Link to={props.link} className="image-button" onClick={doCallback}>
			<div className="square-container image-button__container">
				<div
					className="square-container image-button__img-container"
					style={{backgroundImage: `url('${props.image}')`}}
				>
				</div>
				<div className="image-button__img-arrow-container green-background"></div>
			</div>
			<span className="image-button__text-container p-top__large mobile-p-top__x-large">
				{getText()}
			</span>
		</Link>
	)
}

export default ImageButton
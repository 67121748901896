import React, { useEffect } from 'react'

import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import SummaryTile from '../components/SummaryTile'
import Button from '../components/Button'
import Checkbox from '../components/forms/Checkbox'

import {en, es} from '../languages/langs'
import {countryCodes} from '../data/countryCodes'
import {api} from '../services/api'

const UserSettingsPage = ({activeLang, activeUser, loggedIn}) => {
	const lang = {en, es}[activeLang]

	const renderedCountrySelect = []
	for (let code in countryCodes) {
		renderedCountrySelect.push(<option key={code} value={code}>{countryCodes[code]}</option>)
	}

	function checkAllInterestSections() {
		if (document.getElementById('interest-zoo-animals').checked) {
			document.getElementById('interest-birds').checked = true
			document.getElementById('interest-aquatic').checked = true
			document.getElementById('interest-primates').checked = true
			document.getElementById('interest-small-mammals').checked = true
			document.getElementById('interest-reptiles').checked = true
			document.getElementById('interest-carnivores').checked = true
		}
		else {
			document.getElementById('interest-birds').checked = false
			document.getElementById('interest-aquatic').checked = false
			document.getElementById('interest-primates').checked = false
			document.getElementById('interest-small-mammals').checked = false
			document.getElementById('interest-reptiles').checked = false
			document.getElementById('interest-carnivores').checked = false
		}
	}

	async function sendRegistrationForm() {
		const salutation = [...document.getElementById('register-form__salutation').children].filter(opt => opt.selected)[0].value
		const institution = [...document.getElementById('register-form__institution').children].filter(opt => opt.selected)[0].value
		const country = [...document.getElementById('register-form__country').children].filter(opt => opt.selected)[0].value
		const fields = [...document.getElementById('register-form--update').getElementsByTagName('INPUT')].map(input => {
			return {
				"type": input.type,
				"mandatory": input.classList.contains('register-form__input--mandatory'),
				"name": input.id,
				"checked": input.checked,
				"ref": input
			}
		});
		[...document.getElementById('register-form__part4').getElementsByTagName('TEXTAREA')].forEach(ta => {
			fields.push({
				"type": "text",
				"name": ta.name,
				"mandatory": false,
				"ref": ta
			})
		});
		if (country === 'empty') {
			document.getElementById('register-form__country').classList.add('register-form__input--has-error')
		}
		else {
			document.getElementById('register-form__country').classList.remove('register-form__input--has-error')
		}
		if (institution === 'empty') {
			document.getElementById('register-form__institution').classList.add('register-form__input--has-error')
		}
		else {
			document.getElementById('register-form__institution').classList.remove('register-form__input--has-error')
		}
		const errorFields = fields.filter(field => {
			if ((field.type === 'text' || field.type === 'password') && field.mandatory && field.ref.value === '') {
				field.ref.classList.add('register-form__input--has-error')
				return field
			} else {
				field.ref.classList.remove('register-form__input--has-error')
			}

			if (field.type === 'email'
				&& field.mandatory
				&& (field.ref.value === ''
					|| !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field.ref.value)
					)
			) {
				field.ref.classList.add('register-form__input--has-error')
				return field
			} else {
				field.ref.classList.remove('register-form__input--has-error')
			}
		})
		if (errorFields.length > 0 || country === 'empty' || institution === 'empty') {
			alert(lang['mand_info_missing'])
			return
		}
		const pfield = fields.find(field => field.name === 'register-form--password')
		const rpfield = fields.find(field => field.name === 'register-form--rpassword')
		if (pfield.ref.value !== rpfield.ref.value) {
			alert(lang['passwd_mismatch'])
			return
		}
		const application = {
			"email": fields.find(field => field.name === 'register-form--email').ref.value,
			"first_name": fields.find(field => field.name === 'register-form--fname').ref.value,
			"last_name": fields.find(field => field.name === 'register-form--lname').ref.value,
			"title": salutation,
			"position": fields.find(field => field.name === 'register-form--position').ref.value,
			"country": country,
			"city": fields.find(field => field.name === 'register-form--city').ref.value,
			"organisation": {
				"type": institution,
				"name": fields.find(field => field.name === 'register-form--nameofinstitution').ref.value
			},
			"phone": fields.find(field => field.name === 'register-form--phone').ref.value,
			"facebook_page": fields.find(field => field.name === 'register-form--fbpage').ref.value,
			"mobile_phone": fields.find(field => field.name === 'register-form--whatsapp').ref.value,
			"short_description": fields.find(field => field.name === 'shortdesc').ref.value,
			"public_zoos_relation": fields.find(field => field.name === 'which-public-zoos').ref.value,
			"animal_related_association": fields.find(field => field.name === 'animal-assoc').ref.value,
			"phone_extra_description": fields.find(field => field.name === 'phone-extra-description').ref.value,
			"interests": {
				"zoo_animals": fields.find(field => field.name === 'interest-zoo-animals').checked,
				"birds": fields.find(field => field.name === 'interest-birds').checked,
				"aquatic_species": fields.find(field => field.name === 'interest-aquatic').checked,
				"primates": fields.find(field => field.name === 'interest-primates').checked,
				"small_mammals": fields.find(field => field.name === 'interest-small-mammals').checked,
				"reptiles": fields.find(field => field.name === 'interest-reptiles').checked,
				"carnivores": fields.find(field => field.name === 'interest-carnivores').checked,
			},
			"receive_surplus_wanted": fields.find(field => field.name === 'like-to-receive-surplus').checked
		}

		if (fields.find(field => field.name === 'register-form--password').ref.value !== '') {
			// Only update password when something is filled in
			application.password = fields.find(field => field.name === 'register-form--password').ref.value
		}

		const response = await api.post(
			 `/member-settings?id=${activeUser.id}`,
			 application
		)
		if (response.status === 200) {
			alert(lang['Settings updated successfully'])
		}
	}

	useEffect(() => {
		const getUserSettings = async () => {
			try {
				const response = await api.get(`/member-settings?id=${activeUser.id}`)
				if (response.status === 200 && response.data.data !== undefined) {
					const user = response.data.data
					const titleOpt = [...document.getElementById('register-form__salutation').getElementsByTagName('option')].find(opt => opt.value === user.title)
					titleOpt.selected = true
					document.getElementById('register-form--fname').value = user.first_name
					document.getElementById('register-form--lname').value = user.last_name
					const cntryOpt = [...document.getElementById('register-form__country').getElementsByTagName('option')].find(opt => opt.innerHTML === user.country)
					cntryOpt.selected = true
					document.getElementById('register-form--city').value = user.city
					document.getElementById('register-form--email').value = user.email
					document.getElementById('register-form--whatsapp').value = user.mobile_phone
					const toiOpt = [...document.getElementById('register-form__institution').getElementsByTagName('option')].find(opt => opt.value === user.organisation.type)
					toiOpt.selected = true
					document.getElementById('register-form--nameofinstitution').value = user.organisation.name
					document.getElementById('register-form--phone').value = user.phone
					document.getElementById('register-form--position').value = user.position
					document.getElementById('register-form--website').value = user.website
					document.getElementById('register-form--fbpage').value = user.facebook_page
					if (user.receive_surplus_wanted) {
						document.getElementById('like-to-receive-surplus').checked = true
					} else {
						document.getElementById('do-not-receive-surplus').checked = true
					}
					document.getElementById('interest-zoo-animals').checked = user.interests.zoo_animals
					document.getElementById('interest-aquatic').checked = user.interests.aquatic_species
					document.getElementById('interest-birds').checked = user.interests.birds
					document.getElementById('interest-carnivores').checked = user.interests.carnivores
					document.getElementById('interest-small-mammals').checked = user.interests.small_mammals
					document.getElementById('interest-reptiles').checked = user.interests.reptiles
					document.getElementById('shortdesc').value = user.short_description
					document.getElementById('which-public-zoos').value = user.public_zoos_relation
					document.getElementById('animal-assoc').value = user.animal_related_association
					document.getElementById('phone-extra-description').value = user.phone_extra_description
				}
			} catch (e) {
				console.log(e)
			}
		}
		getUserSettings()
	}, [activeUser])

	return (
		<React.Fragment>
			<PageHeader titleKey="Update your registration details" image="images/tiger-john2.jpg" bgPos="60% 50%" activeLang={activeLang} />
			{loggedIn &&
			<React.Fragment>
				<Section extraClasses="content-width center">
					<Row extraClasses="m-top__large">
						<Column extraClasses="size__one-of-one register-form" id="register-form--update">
							<SummaryTile
								id="register-form--update__part1"
								titleKey="Personal information"
								extraClasses="m-top__x-large register-form--update__part p-right__medium p-bottom__large mobile-p-left__x-large"
								subTitleKey="About you"
								showButton={false}
								activeLang={activeLang}
							>
								<div className="summary-tile__content m-top__x-large">
									<Row>
										<Column extraClasses="size__two-of-twelve">
											<select name="register-form__salutation" id="register-form__salutation" className="register-form__salutation select--creme">
												<option value="Mr.">Mr.</option>
												<option value="Mrs.">Mrs.</option>
												<option value="Ms.">Ms.</option>
												<option value="Dr.">Dr.</option>
												<option value="Ing.">Ing.</option>
											</select>
										</Column>
										<Column extraClasses="size__five-of-twelve p-left__medium mobile-p-left__none mobile-m-top__medium">
											<input
												type="text"
												className="register-form__input register-form__input--mandatory"
												placeholder={`${lang['First Name']}*`}
												id="register-form--fname"
											/>
										</Column>
										<Column extraClasses="column size__five-of-twelve p-left__medium mobile-p-left__none mobile-m-top__medium">
											<input
												type="text"
												className="register-form__input register-form__input--mandatory"
												placeholder={`${lang['Last Name']}*`}
												id="register-form--lname"
											/>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<select name="register-form__country" id="register-form__country" className="register-form__country select--creme">
												{renderedCountrySelect}
											</select>
										</Column>
										<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
											<input
												type="text" 
												className="register-form__input register-form__input--mandatory"
												placeholder={`${lang['City']}*`}
												id="register-form--city"
											/>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<input
												type="email"
												className="register-form__input register-form__input--mandatory"
												placeholder={`${lang['Email address']}*`}
												id="register-form--email"
											/>
										</Column>
										<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
											<input
												type="text" 
												className="register-form__input"
												placeholder="Whatsapp"
												id="register-form--whatsapp"
											/>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<input
												type="password"
												className="register-form__input"
												placeholder={`${lang['Create password']}*`}
												id="register-form--password"
											/>
										</Column>
										<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
											<input
												type="password" 
												className="register-form__input"
												placeholder={`${lang['Repeat Password']}*`}
												id="register-form--rpassword"
											/>
										</Column>
									</Row>
									<Row>
										<Column extraClasses="size__one-of-one text--centered text--small">
											({lang['Leave blank to keep password unchanged']})
										</Column>
									</Row>
									<Row extraClasses="m-top__large"></Row> {/* Just to space the button down */}
								</div>
							</SummaryTile>
							<SummaryTile
								id="register-form__part2"
								titleKey="Corporate information"
								extraClasses="m-top__x-large register-form--update__part p-right__medium p-bottom__large mobile-p-left__x-large"
								subTitleKey="About your institution"
								showButton={false}
								activeLang={activeLang}
							>
								<div className="summary-tile__content m-top__x-large">
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<select name="register-form__institution" id="register-form__institution" className="register-form__institution type select--creme" defaultValue="Type of institution">
												<option value="PBF">{lang['Private breeding farm']}</option>
												<option value="AS">{lang['Animal supplier']}</option>
												<option value="Z">{lang['Zoo and public facilities']}</option>
												<option value="ACON">{lang['Animal consult and vet']}</option>
											</select>
										</Column>
										<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
											<input
												type="text" 
												className="register-form__input register-form__input--mandatory"
												placeholder={`${lang['Name of insitution']}*`}
												id="register-form--nameofinstitution"
											/>
										</Column>
									</Row>
									<Row>
										<Column extraClasses="size__one-of-one text--centered text--small">
											({lang['In case you are a private person']})
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<input
												type="text"
												className="register-form__input"
												placeholder={lang['Phone']}
												id="register-form--phone"
											/>
										</Column>
										<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
											<input
												type="text" 
												className="register-form__input"
												placeholder={lang['Your position']}
												id="register-form--position"
											/>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<input
												type="text"
												className="register-form__input"
												placeholder={lang['Website']}
												id="register-form--website"
											/>
										</Column>
										<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
											<input
												type="text" 
												className="register-form__input"
												placeholder={lang['Facebook page']}
												id="register-form--fbpage"
											/>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<label htmlFor="no-website">
												<input
													type="checkbox"
													className="register-form__input"
													name="no-website"
													id="no-website"
												/>
												<span>{lang['No Website']}</span>
											</label>
										</Column>
									</Row>
								</div>
							</SummaryTile>
							<SummaryTile
								id="register-form__part3"
								titleKey="Details of the interest"
								extraClasses="m-top__x-large register-form--update__part register-form__part p-right__medium p-bottom__large mobile-p-left__x-large"
								subTitleKey="Sections of interest (select the sections you are interested in)"
								showButton={false}
								activeLang={activeLang}
							>
								<div className="summary-tile__content m-top__x-large">
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<Checkbox
												activeLang={activeLang}
												extraClasses="interest-checkbox"
												id="interest-zoo-animals"
												labelKey="Zoo animals (all categories, mammals, birds, reptiles, fishes, etc.)"
												onClick={() => checkAllInterestSections()}
											/>
										</Column>
										<Column extraClasses="size__one-of-two">
											<Checkbox
												activeLang={activeLang}
												extraClasses="interest-checkbox"
												id="interest-small-mammals"
												labelKey="Small mammals (marmosets, kangaroos, rodents, etc.)"
											/>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<Checkbox
												activeLang={activeLang}
												extraClasses="interest-checkbox"
												id="interest-aquatic"
												labelKey="Aquatic species (Sealions, otters, pengiuns, sharks, etc)"
											/>
										</Column>
										<Column extraClasses="size__one-of-two">
											<Checkbox
												activeLang={activeLang}
												extraClasses="interest-checkbox"
												id="interest-primates"
												labelKey="Primates"
											/>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<Checkbox
												activeLang={activeLang}
												extraClasses="interest-checkbox"
												id="interest-birds"
												labelKey="Birds (all species of birds)"
											/>
										</Column>
										<Column extraClasses="size__one-of-two">
											<Checkbox
												activeLang={activeLang}
												extraClasses="interest-checkbox"
												id="interest-reptiles"
												labelKey="Reptiles, Amphibians, Fishes, others"
											/>
										</Column>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-two">
											<Checkbox
												activeLang={activeLang}
												extraClasses="interest-checkbox"
												id="interest-carnivores"
												labelKey="Carnivores"
											/>
										</Column>
										<Column extraClasses="size__one-of-two">
										</Column>
									</Row>
									<Row extraClasses="m-top__medium m-bottom__medium">
										<span className="sub-title sub-title--follows-h3 browngrey-text">
											{lang['Information about available and wanted species']}
										</span>
									</Row>
									<Row extraClasses="m-top__medium">
										<Column extraClasses="size__one-of-one">
											<Checkbox
												activeLang={activeLang}
												id="like-to-receive-surplus"
												labelKey="register_would_like_to_receive_surplus"
												boxColumnSize="small"
											/>
										</Column>
										<Column extraClasses="size__one-of-one">
											<Checkbox
												activeLang={activeLang}
												id="do-not-receive-surplus"
												labelKey="register_do_not_want_to_receive_surplus"
												boxColumnSize="small"
												extraClasses="m-top__medium"
											/>
										</Column>
									</Row>
								</div>
							</SummaryTile>
							<SummaryTile
								id="register-form__part4"
								titleKey="More information"
								extraClasses="m-top__x-large register-form--update__part register-form__part p-right__medium p-bottom__large mobile-p-left__x-large"
								subTitleKey=""
								showButton={false}
								activeLang={activeLang}
							>
								<div className="summary-tile__content m-top__x-large">
									<h5 className="green-text">
										{lang['register_modal_title_1']}
									</h5>
									<textarea name="shortdesc" id="shortdesc" cols="30" rows="3" placeholder={lang['Your answer']}></textarea>
									<h5 className="green-text m-top__medium">
										{lang['register_modal_title_2']}
									</h5>
									<textarea name="which-public-zoos" id="public" cols="30" rows="3" placeholder={lang['Your answer']}></textarea>
									<h5 className="green-text m-top__medium">
										{lang['register_modal_title_3']}
									</h5>
									<textarea name="animal-assoc" id="animal-assoc" cols="30" rows="3" placeholder={lang['Your answer']}></textarea>
									<h5 className="green-text m-top__medium">
										{lang['register_modal_title_4']}
									</h5>
									<input type="text" name="phone-extra-description" id="phone-extra-description" placeholder={lang['Your answer']} />
								</div>
								<Row extraClasses="m-top__large">
									<Button onClick={() => sendRegistrationForm()} extraClasses="m-top__large">{lang['Submit']}</Button>
								</Row>
							</SummaryTile>
						</Column>
					</Row>
				</Section>
			</React.Fragment>
			}
			{!loggedIn &&
				<React.Fragment>
					<Section extraClasses="content-width center text--centered m-top__large m-bottom__large">
						<h4>{lang['loginwarn_inquiry']}</h4>
						<Button link="/login">{lang['Login']}</Button>
					</Section>
				</React.Fragment>
			}
		</React.Fragment>
	)
}

export default UserSettingsPage
import React from 'react'

const Row = props => {
	return (
		<div className={`row${props.extraClasses === undefined ? '' : ` ${props.extraClasses}`}`}>
			{props.children}
		</div>
	)
}

export default Row
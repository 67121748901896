import React from 'react'
import {Link} from 'react-router-dom'
import { speciesMenuSkeleton } from '../data/speciesMenuSkeleton'
import {en, es} from '../languages/langs'

const CatalogItem = ({specimen, updateActiveRoute, setActiveCatalogPage, activeLang}) => {
	const lang = {en, es}[activeLang]
	const coverImage = specimen.images.find(image => image.is_cover_new_website) || specimen.images.find(image => image.is_cover_old_website)
	const orderPath = window.location.pathname.split('/').length === 2 ? `${specimen.order_name}/` : ''
	let path = `${window.location.pathname}/${orderPath}${specimen.scientific_name.toLowerCase().replace(/\s/g, '_').replace(/\//g, '')}`;
	path = path.indexOf('/all') === 0 ? path.replace(/^\/all/, specimen.class_name) : path
	path = path.indexOf('/search') === 0 ? path.replace(/^\/search/, specimen.class_name) : path

	let image = ''
	const updateCatalogPage = () => {
		if (setActiveCatalogPage !== undefined) {
			setActiveCatalogPage(1)
		}
	}
	if (coverImage) {
		image = coverImage.src
	} else if (specimen.images[0]) {
		image = specimen.images[0].src
	}

	return (
		<div className="row catalog-item mobile-p-left__large mobile-p-right__large mobile-m-top__x-large">
			<div className="column size__one-of-two mobile-size__one-of-two">
				<Link
					to={path}
					onClick={() => {
						updateActiveRoute();
						window.scrollTo(0,0);
						updateCatalogPage();
					}}
					className="image-button m-bottom__large"
				>
					<div className="square-container image-button__container">
						<div
							className="square-container image-button__img-container"
							style={{backgroundImage: `url('${image}')`}}
						>
						</div>
						<div className="image-button__img-arrow-container green-background">
							<span className="creme-text">{lang['Learn more']}</span>
						</div>
					</div>
				</Link>
			</div>
			<div className="column size__one-of-two p-left__medium mobile-size__one-of-two">
				<h4 className="catalog-item__title green-text p-right__medium m-bottom__mediumsmall">
					{activeLang === 'en' ? specimen.common_name.substring(0, 35) : specimen.spanish_name.substring(0, 35)} - {specimen.scientific_name}
				</h4>
				<span className="catalog-item__desc line-height__large">{specimen.description ? specimen.description.substring(0, 30) : ''}<br/>
				    <i>{specimen.located_at}</i>
				</span>
			</div>
		</div>
	)
}

export default CatalogItem
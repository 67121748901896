import React from 'react'
import Button from './Button'

import {en, es} from '../languages/langs'

const SummaryTile = props => {
	let content, button
	const lang = {en, es}[props.activeLang]
	if (props.children === undefined) {
		content = <p className="summary-tile__content line-height__large">{lang[props.textKey]}</p>
	} else {
		content = props.children
	}

	button = props.showButton !== false ?
		<Button link={props.buttonLink || ''} onClick={props.buttonOnClick !== undefined ? props.buttonOnClick : ''}>{lang[props.buttonTextKey]}</Button>
		: ''
	
	return (
		<div
			className={`summary-tile${props.extraClasses ? ' ' + props.extraClasses : ''}`} id={props.id || ''}>
			<h3 className="green-text summary-tile__title m-bottom__none" id={props.anchor || ''}>
				{props.translate === false ? props.title : lang[props.titleKey]}
			</h3>
			{props.description && 
			  <div className="sub-title sub-title--follows-h3 browngrey-text">{props.description}</div>
			}
			<span className="sub-title sub-title--follows-h3 browngrey-text">{props.translate === false ? props.subTitle : lang[props.subTitleKey]}</span>
			{content}
			{button}
		</div>
	)
}

export default SummaryTile